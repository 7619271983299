import {
    Button,
    Typography
} from '@mui/material';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from 'react';


export interface ResolveUploadModalProps {
    onResolve?: (forceRename: boolean) => void;
    onClose?: () => void;
};

const ResolveUploadModal = (
    props: ResolveUploadModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const [isOpen, setIsOpen] = useState(false);


    const handleClose = () => {
        if (props.onClose != undefined) {
            props.onClose();
        }
    };

    const handleResolve = (forceRename: boolean = false) => {
        if (props.onResolve != undefined) {
            props.onResolve(forceRename);
        }
        close();
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Resolve upload files'}
            maxWidth={'sm'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleResolve(false)}
                    >
                        Replace as New Versions
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleResolve(true)}
                    >
                        Rename and Keep All
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </>
            }
        >
            <Typography>We found that one or multiple files have same names. How do you want to proceed?</Typography>
        </BaseModal>
    </>;
};

export default forwardRef(ResolveUploadModal);