import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import UpdateAccountForm from 'components/UpdateAccountForm';
import { useEffect } from 'react';
import { fetchAccount } from 'slices/accountActions';
import { useAppDispatch } from 'utilities/hooks';

const ProfilePage = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    return <>
        <Container component="main" maxWidth="xs">
            <Stack spacing={5} sx={{ mt: 3 }}>
                <UpdateAccountForm />
            </Stack>
        </Container>
    </>;
};

export default ProfilePage;