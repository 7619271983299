import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invitations } from 'interfaces/invitations';
import { fetchInvitations, resendInvitation, syncInvitations } from 'slices/invitationsActions';

interface InvitationsState {
    loading: boolean;
    error: any | null;
    invitations: Invitations;
};

export const initialState: InvitationsState = {
    loading: false,
    error: null,
    invitations: [],
};

const invitationsSlice = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        reset: () => initialState,
        updateInvitationRole: (state, action: PayloadAction<any>) => {
            const invitations = state.invitations.map(invitation => {
                if (invitation.id !== action.payload.id) return invitation;
                invitation.team_role = action.payload.team_role;
                return invitation;
            });
        },
        removeInvitation: (state, action: PayloadAction<any>) => {
            if (!state.invitations) return;
            state.invitations = state.invitations.filter(invitation => invitation.id !== action.payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchInvitations.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchInvitations.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.invitations = action.payload.data;
        })
        builder.addCase(fetchInvitations.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(resendInvitation.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(resendInvitation.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(resendInvitation.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(syncInvitations.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(syncInvitations.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(syncInvitations.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
    updateInvitationRole,
    removeInvitation
} = invitationsSlice.actions;
export default invitationsSlice.reducer;