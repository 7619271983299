import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';


export const fetchAccount = createAsyncThunk('account', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.get('api/v1/account');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateAccount = createAsyncThunk('account/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateEmail = createAsyncThunk('account/email/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/email', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const resendByEmail = createAsyncThunk('account/email/resend', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/email/resend');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const verifyEmail = createAsyncThunk('account/email/verify', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/email/verify', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updatePhone = createAsyncThunk('account/phone/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/phone', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const resendByPhone = createAsyncThunk('account/phone/resend', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/phone/resend');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const verifyPhone = createAsyncThunk('account/phone/verify', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/account/phone/verify', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updatePassword = createAsyncThunk('account/password/update', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.put('api/v1/account/password', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});