import {
    Box,
    Button,
    Stack,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return <>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Stack direction="column" spacing={3} sx={{ textAlign: 'center' }}>
                <Typography variant="h1">
                    404
                </Typography>
                <Typography variant="h5">
                    The page you're looking for doesn't exist.
                </Typography>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/')}
                >Go Back</Button>
            </Stack>
        </Box>
    </>;
};

export default NotFoundPage;