import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccessToken } from '../utilities/axios';
import { fetchAccount } from './accountActions';
import { claimOTACode, login, loginOTACode, logout, refresh, signup } from './authActions';

const accessToken = getAccessToken();

interface AuthState {
    loading: boolean;
    error: any | null;
    accessToken: string | null;
    accessTokenExpiresIn: number | null;
};

export const initialState: AuthState = {
    loading: false,
    error: null,
    accessToken: accessToken,
    accessTokenExpiresIn: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState,
        removeAccessAndReset: (state) => {
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            localStorage.removeItem('accessToken');
        }
    },
    extraReducers: builder => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            state.accessToken = action.payload.data.access_token;
            state.accessTokenExpiresIn = action.payload.data.expires_in;
            localStorage.setItem('accessToken', action.payload.data.access_token);
        })
        builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            state.error = action.payload.data;
            localStorage.removeItem('accessToken');
        })

        builder.addCase(signup.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(signup.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(signup.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(refresh.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(refresh.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            state.accessToken = action.payload.data.access_token;
            state.accessTokenExpiresIn = action.payload.data.expires_in;
            localStorage.setItem('accessToken', action.payload.data.access_token);
        })
        builder.addCase(refresh.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            state.error = action.payload.data;
            localStorage.removeItem('accessToken');
        })

        builder.addCase(logout.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            localStorage.removeItem('accessToken');
        })
        builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            state.error = action.payload.data;
        })

        builder.addCase(claimOTACode.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(claimOTACode.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(claimOTACode.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(loginOTACode.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(loginOTACode.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            state.accessToken = action.payload.data.access_token;
            state.accessTokenExpiresIn = action.payload.data.expires_in;
            localStorage.setItem('accessToken', action.payload.data.access_token);
        })
        builder.addCase(loginOTACode.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
            state.error = action.payload.data;
            localStorage.removeItem('accessToken');
        })

        builder.addCase(fetchAccount.rejected, (state, action: PayloadAction<any>) => {
            state.accessToken = null;
            state.accessTokenExpiresIn = null;
        })
    },
});

export const { reset, removeAccessAndReset } = authSlice.actions;
export default authSlice.reducer;