import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';

export const fetchContacts = createAsyncThunk('contacts/list', async (data: {search: string, parent?: string}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const searchParams = new URLSearchParams();
        searchParams.append('search', data.search);
        if (data.parent) {
            searchParams.append('parent', data.parent);
        }

        const response = await axios.get(`api/v1/contacts/list?${searchParams.toString()}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchCompanies = createAsyncThunk('contacts/companies', async (search: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/contacts/companies?search=${search}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});