import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { updateAccount } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const UpdateAccountForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const navigate = useNavigate();
    const { register, reset, handleSubmit } = useForm();

    useEffect(() => {
        if (user) {
            reset({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
            });
        }
    }, [user])

    const onSubmit = (data: any) => {
        dispatch(updateAccount(data));
    };

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">Update Profile</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="first_name"
                label="First Name"
                autoComplete="first_name"
                autoFocus
                {...register("first_name", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.first_name}
                helperText={error?.errors?.first_name?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                autoComplete="last_name"
                {...register("last_name", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.last_name}
                helperText={error?.errors?.last_name?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                margin="normal"
                disabled
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                {...register("email", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.email}
                helperText={error?.errors?.email?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <Box sx={{ mt:1, textAlign: 'right' }}>
                <Typography variant="subtitle2"><Link href="#" onClick={() => {navigate('/profile/email/update')}} color="primary">Update email</Link></Typography>
            </Box>
            <TextField
                margin="normal"
                disabled
                fullWidth
                id="phone"
                label="Phone Number"
                autoComplete="phone"
                autoFocus
                {...register("phone", { required: true, minLength: 9, maxLength: 16 })}
                error={!!error?.errors?.phone}
                helperText={error?.errors?.phone?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <Box sx={{ mt:1, textAlign: 'right' }}>
                <Typography variant="subtitle2"><Link href="#" onClick={() => {navigate('/profile/phone/update')}} color="primary">Update phone number</Link></Typography>
            </Box>
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disableElevation
                sx={{ mt: 3 }}
            >
                Save
            </LoadingButton>
            <Button
                onClick={() => {navigate('/profile/password/update')}}
                fullWidth
                variant="outlined"
                size="large"
                disableElevation
                sx={{ my: 3 }}
            >
                Change Password
            </Button>
        </Box>
    </Box>;
};

export default UpdateAccountForm;