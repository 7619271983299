import DefaultAppBar from 'components/DefaultAppBar';
import { Outlet } from 'react-router-dom';

const TopLayout = () => {
    return <>
        <DefaultAppBar />
        <Outlet />
    </>;
};

export default TopLayout;