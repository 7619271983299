import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { renameDocument } from 'slices/documentsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


export interface DocumentRenameModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const DocumentRenameModal = (
    props: DocumentRenameModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { document, loading, error } = useAppSelector((state) => state.document);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [formId, setFormId] = useState('document_rename_form_' + nanoid());

    useEffect(() => {
        if (document) {
            reset({
                id: document.id,
                name: document.name,
            });
        }
    }, [document]);

    const onSubmit = async (data: any) => {
        dispatch(renameDocument(data))
            .unwrap()
            .then(() => {
                props.onSubmit && props.onSubmit();
                modal?.current?.close();
            }).catch(() => {});
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        reset();
        props.onClose && props.onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            title={'Rename document'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => modal?.current?.close()}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        Rename
                    </LoadingButton>
                </>
            }
        >
            {
                (isOpen) ?
                    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="name"
                            variant="outlined"
                            label="Document Name"
                            {...register("name")}
                            error={!!error?.errors?.name}
                            helperText={error?.errors?.name?.[0]}
                            autoFocus
                            fullWidth
                            required
                        />
                    </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(DocumentRenameModal);