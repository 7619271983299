import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Team } from 'interfaces/teams';
import { Users } from 'interfaces/users';
import {
    addGuestTeamMembers,
    addTeamMembers,
    createGuestTeam,
    createTeam,
    deleteGuestTeamMember,
    deleteTeamMember,
    fetchGuestTeam,
    fetchGuestTeamMembers,
    fetchTeam,
    fetchTeamMembers,
    renameTeam,
    softDeleteGuestTeam,
    softDeleteTeam,
    updateGuestTeamMember,
    updateGuestTeamMembers,
    updateTeam,
    updateTeamMember,
    updateTeamMembers
} from './teamsActions';

interface TeamState {
    loading: boolean;
    error: any | null;
    team: Team | null;
    members: Users | null;
    membersMap: any;
};

export const initialState: TeamState = {
    loading: false,
    error: null,
    team: null,
    members: null,
    membersMap: null,
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        reset: () => initialState,
        updateMemberRole: (state, action: PayloadAction<any>) => {
            if (!state.membersMap) return;

            if (!state.membersMap[action.payload.id]) return;
            state.membersMap[action.payload.id].role = action.payload.role;
        },
        removeMember: (state, action: PayloadAction<any>) => {
            if (!state.members) return;
            state.members = state.members.filter(member => member.id !== action.payload);

            if (!state.membersMap) return;
            if (!state.membersMap[action.payload]) return;
            delete state.membersMap[action.payload];
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.team = action.payload.data;
        })
        builder.addCase(fetchTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(createTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(createTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(renameTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(renameTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(renameTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(updateTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(updateTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(softDeleteTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(softDeleteTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(softDeleteTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetchTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            state.members = action.payload.data;
            state.membersMap = action.payload.map;
        })
        builder.addCase(fetchTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(addTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(addTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(addTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(updateTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(updateTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(updateTeamMember.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateTeamMember.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(updateTeamMember.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(deleteTeamMember.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(deleteTeamMember.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(deleteTeamMember.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetchGuestTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchGuestTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.team = action.payload.data;
        })
        builder.addCase(fetchGuestTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(createGuestTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createGuestTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(createGuestTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(softDeleteGuestTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(softDeleteGuestTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(softDeleteGuestTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetchGuestTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchGuestTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            state.members = action.payload.data;
            state.membersMap = action.payload.map;
        })
        builder.addCase(fetchGuestTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(addGuestTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(addGuestTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(addGuestTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(updateGuestTeamMembers.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateGuestTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(updateGuestTeamMembers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(updateGuestTeamMember.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(updateGuestTeamMember.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(updateGuestTeamMember.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(deleteGuestTeamMember.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(deleteGuestTeamMember.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(deleteGuestTeamMember.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
    removeMember,
    updateMemberRole,
} = teamSlice.actions;
export default teamSlice.reducer;