import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Container,
    Grid,
    Link,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { login } from 'slices/authActions';
import { getURLSearchParam, redirectToCentral, redirectToHost } from 'utilities/axios';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const SigninPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const { accessToken, error, loading } = useAppSelector((state) => state.auth);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const redirect: null | string = getURLSearchParam('redirect');

    const onLogin = async (data: any) => {
        if (redirect) {
            data.redirect = redirect;
        }

        dispatch(login(data))
            .unwrap()
            .then((res) => {
                // navigate('/');
                if (redirect) {
                    redirectToHost(redirect, false);
                } else {
                    redirectToCentral(null, true, false);
                    // if (res.data.tenants.length) {
                    // let currentTenant: Tenant = res.data.tenants[0];
                    // redirectToTenant(currentTenant, false);
                    // } else {
                    //     redirectToCentral('', true, false);
                    // }
                }
            }).catch(() => {});
    };

    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={2} sx={{ py: 6, px: 4 }}>
                    <Stack
                        direction="column"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Sitemax</Typography>
                        <Typography variant="subtitle1">Sign in with Email address</Typography>
                    </Stack>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onLogin)}
                        sx={{ mt: 3 }}
                    >
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            autoComplete="email"
                            autoFocus
                            {...register("email", { required: true, minLength: 3, maxLength: 128 })}
                            error={!!error?.errors?.email}
                            helperText={error?.errors?.email?.[0]}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            {...register("password", { required: true, minLength: 3, maxLength: 32 })}
                            error={!!error?.errors?.password}
                            helperText={error?.errors?.password?.[0]}
                        />
                        <Box sx={{ mt:1, textAlign: 'right' }}>
                            <Typography variant="subtitle2"><Link href="/forgot" color="primary">Forgot Password?</Link></Typography>
                        </Box>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 3 }}
                        >
                            Sign In
                        </LoadingButton>
                    </Box>
                    <Box sx={{ mt:1, textAlign: 'center' }}>
                        <Link href="/register">Don't have an account?</Link>
                    </Box>
                </Paper>
            </Container>
        </Grid>

    </>;
};

export default SigninPage;
