import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';

type ShareableType =
  | 'document';

export const share = createAsyncThunk('shareables/share', async (data: { id: string, user_id: string, type: ShareableType }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post(`api/v1/shareables`, {
            tenant: state.tenants.currentTenant.name,
            shareable_id: data.id,
            shareable_type: data.type,
            user_id: data.user_id
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const shareDocument = createAsyncThunk('shareables/share/document', async (data: { id: string, user_id: string, role: string }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post(`api/v1/shareables`, {
            tenant: state.tenants.currentTenant.name,
            shareable_id: data.id,
            shareable_type: 'document',
            user_id: data.user_id,
            role: data.role,
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const shareCategory = createAsyncThunk('shareables/share/category', async (data: { uuid: string, user_id: string, role: string }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post(`api/v1/shareables`, {
            tenant: state.tenants.currentTenant.name,
            shareable_id: data.uuid,
            shareable_type: 'category',
            user_id: data.user_id,
            role: data.role,
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchAll = createAsyncThunk('shareables/fetchAll', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.get(`api/v1/shareables`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchById = createAsyncThunk('shareables/fetchById', async (id: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.get(`api/v1/shareables/${id}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchByShareable = createAsyncThunk('shareables/fetchByShareable', async (id: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.get(`api/v1/shareables/by/${id}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const deleteById = createAsyncThunk('shareables/deleteById', async (id: string, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;

        const axios = useAxios(state.auth.accessToken);
        const response = await axios.delete(`api/v1/shareables/${id}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});