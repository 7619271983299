import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Categories, Category } from 'interfaces/documents';
import { fetchCategories, fetchCategoriesMap } from './categoriesActions';

interface CategoriesState {
    list: Categories;
    map: any[];
    excluded: number[];
    parent: Category | null;
    breadcrumbs: any[];
    loading: boolean;
    error: any | null;
};

export const initialState: CategoriesState = {
    list: [],
    map: [],
    excluded: [],
    breadcrumbs: [],
    parent: null,
    loading: false,
    error: null
};

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        reset: () => initialState,
        setExcluded: (state, action: PayloadAction<Categories>) => {
            state.excluded = action.payload.map(item => {
                return item.id;
            });
        },
        deleteFromMap: (state, action: PayloadAction<any>) => {
            delete state.map[action.payload];
        },
        resetMap: (state) => {
            state.map = [];
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (action.payload.data.list.length === 0) return;

            state.list = action.payload.data.list;
            state.parent = action.payload.data.parent;
            state.breadcrumbs = action.payload.data.breadcrumbs;
        })
        builder.addCase(fetchCategories.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetchCategoriesMap.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchCategoriesMap.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            if (action.payload.data.list.length === 0) return;

            const list = action.payload.data.list;
            const parent = action.payload.data.parent;

            if (parent) {
                state.map[parent.id] = list;
            }
        })
        builder.addCase(fetchCategoriesMap.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { setExcluded, deleteFromMap, reset, resetMap } = categoriesSlice.actions;
export default categoriesSlice.reducer;
