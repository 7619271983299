import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { updatePassword } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


const UpdatePasswordForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const { register, reset, handleSubmit } = useForm();

    const onSubmit = (data: any) => {
        dispatch(updatePassword(data))
            .unwrap()
            .then(() => {
                reset();
            }).catch(() => {});
    };

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">Update Password</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
        >
            <TextField
                margin="normal"
                fullWidth
                label="Current Password"
                type="password"
                id="current_password"
                {...register("current_password", { required: true, minLength: 6, maxLength: 32 })}
                error={!!error?.errors?.current_password}
                helperText={error?.errors?.current_password?.[0]}
            />
            <TextField
                margin="normal"
                fullWidth
                label="New Password"
                type="password"
                id="password"
                {...register("password", { required: true, minLength: 6, maxLength: 32 })}
                error={!!error?.errors?.password}
                helperText={error?.errors?.password?.[0]}
            />
            <TextField
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                id="password_confirmation"
                {...register("password_confirmation")}
                error={!!error?.errors?.password_confirmation}
                helperText={error?.errors?.password_confirmation?.[0]}
            />
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disableElevation
                sx={{ mt: 3, mb: 3 }}
            >
                Save
            </LoadingButton>
        </Box>
    </Box>;
};

export default UpdatePasswordForm;