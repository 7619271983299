import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { resendByPhone, verifyPhone } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const VerifyPhoneForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const { register, reset, handleSubmit } = useForm();

    const onVerify = (data: any) => {
        dispatch(verifyPhone(data))
            .unwrap()
            .then(() => {
            }).catch(() => {});
    };

    const handleResend = () => {
        dispatch(resendByPhone())
            .unwrap()
            .then(() => {
            }).catch(() => {});
    };


    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">We send a code on your phone number. Please, check your enter code to verify your phone number.</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onVerify)}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="phone_verification_code"
                label="Verification Code"
                autoComplete="phone_verification_code"
                {...register("phone_verification_code", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.phone_verification_code}
                helperText={error?.errors?.phone_verification_code?.[0]}
            />
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{ mt: 3, mb: 3 }}
            >
                Save
            </LoadingButton>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle2">Didn't receive the code? <Link href="#" onClick={handleResend}>Resend code</Link></Typography>
            </Box>
        </Box>
    </Box>;
};

export default VerifyPhoneForm;