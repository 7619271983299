import {
    ArrowBack,
    Description
} from '@mui/icons-material';
import {
    Box,
    Chip,
    DialogTitle,
    IconButton,
    LinearProgress,
    Stack,
    Typography
} from '@mui/material';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { nanoid } from '@reduxjs/toolkit';
import {
    Document,
    Markup
} from 'interfaces/documents';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import Moment from 'react-moment';
import {
    reset
} from 'slices/documentSlice';
import {
    fetchDocument,
    fetchMarkup,
    saveMarkup,
    updateDocument
} from 'slices/documentsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


export interface DocumentMarkupModalProps {
    readonly?: boolean;
    onSubmit?: () => void;
    onClose?: () => void;
};

const DocumentMarkupModal = (
    props: DocumentMarkupModalProps,
    ref: React.Ref<unknown>
) => {
    const dispatch = useAppDispatch();
    const modal = useRef<BaseModalRef>(null);
    const { user } = useAppSelector((state) => state.account);
    const { document, markup, loading, error } = useAppSelector((state) => state.document);
    const webViewerRef = useRef<null | HTMLElement>(null);
    const [webViewer, setWebViewer] = useState<null | WebViewerInstance>(null);
    const [webViewerId, setWebViewerId] = useState('document_markup_viewer_' + nanoid());
    const [doesWebViewerListen, setDoesWebViewerListen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [autoSavedAt, setAutoSavedAt] = useState<null | number>(null);
    // const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

    useEffect(() => {
        /**
         * Only when opened
         */
        if (!isOpen) return;
        /**
         * Init WebViewer once, if DOM exists.
         * It`s expensive so we don't want to repeat it every time.
         */
        if (!webViewer) {
            if (!webViewerRef.current) return;
            makeWebViewer(webViewerRef.current as HTMLElement);
        }
        /**
         * Fetch Document URL
         * We did setDocument before open this modal,
         * but this Document doesn't have URL,
         * because the generation of temporary AWS URL is time consuming,
         * so we do that on demand.
         */
        if (!document?.url) {
            dispatch(fetchDocument(document?.id));
        }
    }, [isOpen]);

    useEffect(() => {
        if (!document) return;
        if (!webViewer) return;

        /**
         * WebViewer is ready, add event listeners and more.
         */
        if (!doesWebViewerListen) {
            addListeners(webViewer as WebViewerInstance, document);
            defineReadOnly(webViewer as WebViewerInstance, document);
        }

        /**
         * WebViewer is ready and Document URL is ready, load document into the WebViewer.
         */
        if (document.url) {
            loadDocument(document);
        }
    }, [webViewer, document]);

    useEffect(() => {
        if (!markup) return;

        /**
         * Markup is ready, import markup into the WebViewer.
         */
        if (markup.xfdf) {
            importMarkups(markup);
        }
    }, [markup]);

    /**
     * Init the WebViewer.
     *
     * @param {HTMLElement} webViewerEl - The HTML element to render the WebViewer in.
     */
    const makeWebViewer = (webViewerEl: HTMLElement) => {
        if (webViewer) return;

        WebViewer({
            path: '/webviewer/lib',
            isAdminUser: false,
            isReadOnly: true,
            annotationUser: user?.name || 'Guest',
            useDownloader: false,
            preloadWorker: 'all',
            enableOptimizedWorkers: true,
            enableOfficeEditing: false,
            disableLogs: false,
            fullAPI: true,
        }, webViewerEl)
            .then(instance => {
                /**
                 * Make instance available as state.
                 */
                setWebViewer(instance);
            });
    };

    /**
     * Loads a document into the WebViewer.
     *
     * @param {Document} document - The document to load.
     */
    const loadDocument = (document: Document) => {
        if (!webViewer) return;

        webViewer.UI.loadDocument(document.url, {
            filename: document.name,
            extension: document.extension
        });
    };

    /**
     * Import markups from a markup object into the annotation manager of the WebViewer.
     *
     * @param {Markup} markup - The markup object containing the markups to import.
     */
    const importMarkups = (markup: Markup) => {
        if (!webViewer) return;

        webViewer.Core.annotationManager.importAnnotations(markup.xfdf);
    };

    /**
     * Cached callback of saveMarkup action
     */
    const _saveMarkup = useCallback((xfdfData: string) => {
        return saveMarkup({
            id: document?.id,
            data: {
                xfdf: xfdfData
            }
        });
    }, [document]);

    /**
     * Cached callback of updateDocument action
     */
    const _updateDocument = useCallback((formData: any) => {
        return updateDocument({
            id: document?.id,
            file: formData,
        });
    }, [document]);

    /**
     * Cached callback of fetchMarkup action
     */
    const _fetchMarkup = useCallback(() => {
        return fetchMarkup(document?.id);
    }, [document]);


    const annotationsToFormData = async (webViewer: WebViewerInstance) => {
        const xfdfString = await webViewer.Core.annotationManager.exportAnnotations();
        const fileData = await webViewer.Core.documentViewer.getDocument().getFileData({ xfdfString, includeAnnotations: true });
        const formData = new FormData();
        formData.append('file', new Blob([
            new Uint8Array(fileData),
        ], {
            type: 'application/pdf',
        }));

        return formData;
    };

    /**
     * Adds event listeners to the WebViewer instance.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance.
     */
    const addListeners = async (webViewer: WebViewerInstance, document: Document) => {
        /**
         * Load Markups
         */
        // webViewer.Core.documentViewer.addEventListener('documentLoaded.custom', () => {
        //     dispatch(_fetchMarkup());
        // });

        /**
         * Save Markups
         */
        if (!props.readonly) {
            // await webViewer.Core.PDFNet.initialize();

            if (document.mime_type.includes('pdf') || document.mime_type.includes('image')) { //  || document.mime_type.includes('image')
                webViewer.Core.annotationManager.addEventListener('annotationChanged.custom', async (annotations, action, params) => {
                    if (params?.imported) return;

                    const formData = await annotationsToFormData(webViewer);
                    dispatch(_updateDocument(formData));
                    setAutoSavedAt(Date.now());
                });
                webViewer.Core.documentViewer.addEventListener('pagesUpdated.custom', async (e, b, c) => {
                    const formData = await annotationsToFormData(webViewer);
                    dispatch(_updateDocument(formData));
                    setAutoSavedAt(Date.now());
                });
            }
            // if (document.mime_type.includes('image')) {
            //     webViewer.Core.annotationManager.addEventListener('annotationChanged.custom', async (annotations, action, params) => {
            //         if (params?.imported) return;
            //         const doc = await webViewer.Core.documentViewer.getDocument().getPDFDoc();
            //         const pdfdraw = await webViewer.Core.PDFNet.PDFDraw.create(92);
            //         const iterator = await doc.getPageIterator(1);
            //         const currentPage = await iterator.current();
            //         const fileData = await pdfdraw.exportBuffer(currentPage, 'PNG');
            //         const formData = new FormData();
            //         formData.append('file', new Blob([
            //             new Uint8Array(fileData),
            //         ], {
            //             type: 'image/png',
            //         }));

            //         dispatch(_updateDocument(formData));

            //         setAutoSavedAt(Date.now());
            //     });
            // }
        }

        setDoesWebViewerListen(true);
    };

    /**
     * Define the Read-only Mode of WebViewer instance based on Props.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance.
     */
    const defineReadOnly = (webViewer: WebViewerInstance, document: Document) => {
        if (props.readonly) {
           webViewer.Core.documentViewer.enableReadOnlyMode();
           webViewer.Core.annotationManager.enableReadOnlyMode();
        } else {
            /**
             * Allow markups only for PDF and Images
             */
            if (document.mime_type.includes('pdf') || document.mime_type.includes('image')) { // || document.mime_type.includes('image')
                webViewer.Core.documentViewer.disableReadOnlyMode();
                webViewer.Core.annotationManager.disableReadOnlyMode();
            } else {
                webViewer.Core.documentViewer.enableReadOnlyMode();
                webViewer.Core.annotationManager.enableReadOnlyMode();
            }
        }
    };

    /**
     * Removes the event listeners from the WebViewer instance.
     *
     * @param {WebViewerInstance} webViewer - The WebViewer instance from which event listeners will be removed.
     */
    const removeListeners = (webViewer: WebViewerInstance) => {
        webViewer.Core.documentViewer.removeEventListener('pagesUpdated.custom');
        webViewer.Core.documentViewer.removeEventListener('documentLoaded.custom');
        webViewer.Core.annotationManager.removeEventListener('annotationChanged.custom');
        setDoesWebViewerListen(false);
    };

    /**
     * Handles the action when the close event is triggered.
     */
    const handleOnClose = () => {
        if (document) {
            /**
             * Reset the state
             */
            dispatch(reset());
        }

        if (webViewer) {
            /**
             * Close opened document,
             * so it won't be shown on the next opening
             * as WebViewer will be already initialized.
             */
            webViewer.UI.closeDocument();

            /**
             * Remove listeners to make sure WebViewer works with the right Document.
             */
            removeListeners(webViewer);
        }

        /**
         * Reset autosave time
         */
        setAutoSavedAt(null);

        props.onClose && props.onClose();
    };

    /**
     * Modal features
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            titleComponent={
                <DialogTitle sx={{position: "relative"}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            size="large"
                            color="primary"
                            onClick={close}
                        >
                            <ArrowBack />
                        </IconButton>
                        {props.readonly ? 'View document' : 'Markup document'}
                        <Chip color="primary" icon={<Description fontSize="small" />} sx={{ml: 1}} label={document?.name}/>
                        {
                            autoSavedAt &&
                            <Typography variant="body2">Autosaved (<Moment fromNow>{autoSavedAt}</Moment>)</Typography>
                        }
                    </Stack>
                    {loading && <LinearProgress sx={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%'
                    }}/>}
                </DialogTitle>
            }
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            disablePadding
            fullScreen
        >
            <Box
                id={webViewerId}
                ref={webViewerRef}
                width={1}
                height={'calc(100vh - 80px)'}
            />
        </BaseModal>
    </>;
};

export default forwardRef(DocumentMarkupModal);