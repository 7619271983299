import {
    Close as CloseIcon
} from '@mui/icons-material';
import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import { useEffect } from 'react';
import Moment from 'react-moment';
import { deleteById } from 'slices/shareablesActions';
import { useAppDispatch } from 'utilities/hooks';


const ShareablesList = ({
    items = [],
    onDelete
}: {
    items: any[];
    onDelete?: () => void;
}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
    }, []);

    const handleDelete = (shareable: any) => {
        dispatch(deleteById(shareable.id))
            .unwrap()
            .then(() => {
                onDelete && onDelete();
            }).catch(() => {});
    };

    if (items?.length === 0) {
        return <></>;
    }

    return <List>
        {items.map((shareable: any, index: number) => {
            return <ListItemButton key={index} disableGutters>
                <ListItem secondaryAction={
                    <IconButton edge="end" onClick={() => {
                        handleDelete(shareable);
                    }}>
                        <CloseIcon />
                    </IconButton>
                }>
                    <ListItemAvatar>
                        <Avatar>{shareable?.user.initials}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<>
                            {shareable?.user.name} <Typography  variant="subtitle1" component="span">(<Moment fromNow>{shareable?.updated_at}</Moment>)</Typography>
                        </>}
                        secondary={shareable?.expires_at && <>
                            Expires at <Moment format="lll">{shareable?.expires_at}</Moment>
                        </>}
                    />
                    <Typography variant="subtitle1" sx={{pr: 1, textTransform: "capitalize"}}>{shareable?.role}</Typography>
                </ListItem>
            </ListItemButton>;
        })}
    </List>;
};

export default ShareablesList;