import {
    Button,
    Typography
} from '@mui/material';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

export interface ConfirmationModalProps {
    title?: string;
    text?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onCancel?: (value: Boolean) => void;
    onConfirm?: (value: Boolean) => void;
    onResolve?: (value: Boolean) => void;
    onClose?: () => void;
};

const ConfirmationModal = (
    props: ConfirmationModalProps,
    ref: React.Ref<unknown>
) => {
    const {title, text, confirmLabel, cancelLabel, onCancel, onConfirm, onResolve, onClose} = props;
    const modal = useRef<BaseModalRef>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleConfirm = () => {
        onConfirm && onConfirm(true);
        handleResolve(true);
    };

    const handleCancel = () => {
        onCancel && onCancel(false);
        handleResolve(false);
    };

    const handleResolve = (hasConfirmed: boolean = false) => {
        onResolve && onResolve(hasConfirmed);
        close();
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={title ? title : 'Confirmation'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleCancel()}
                    >
                        {cancelLabel ? cancelLabel : 'Cancel'}
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleConfirm()}
                    >
                        {confirmLabel ? confirmLabel : 'Confirm'}
                    </Button>
                </>
            }
        >
            <Typography>{text}</Typography>
        </BaseModal>
    </>;
};

export default forwardRef(ConfirmationModal);