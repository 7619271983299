import {
    Add,
    Close,
    FolderOpen,
    Search
} from '@mui/icons-material';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { debounce } from '@mui/material/utils';
import {
    useEffect,
    useMemo,
    useState
} from 'react';

export interface CustomDropdownItem {
    id: string,
    title: string,
    subtitle?: string,
};

export interface CustomDropdownProps extends MenuProps {
    subHeader?: string,
    createLabel?: string,
    loading?: boolean,
    onSelect: (value: any) => void,
    onCreate: (callback?: (value: any) => void) => void,
    onDebounce: (value: string, callback: (value: any) => void) => void,
};

const CustomDropdown = (
    props: CustomDropdownProps
) => {
    const {
        open,
        loading,
        subHeader,
        createLabel,
        onCreate,
        onSelect,
        onDebounce,
        ...customDropdownProps
    } = props;

    const [items, setItems] = useState<CustomDropdownItem[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const debounceInputChange = useMemo(() => {
        return debounce((newValue: string) => {
            onDebounce(newValue, (items: CustomDropdownItem[]) => {
                setItems(items);
            });
        }, 400);
    }, [onDebounce]);

    useEffect(() => {
        if (!open) return;
        debounceInputChange('');
    }, [open]);


    const handleCreateClick = () => {
        onCreate && onCreate(() => {
            setSearchValue('');
            debounceInputChange('');
        });
    };

    const handleItemSelect = (item: CustomDropdownItem) => {
        onSelect && onSelect(item);
    };

    const handleSearchChange = (value: string) => {
        setSearchValue(value);
        debounceInputChange(value);
    };

    const listSubHeader = (subHeader && <ListSubheader>
        <Typography variant='subtitle2' my={1} noWrap>{subHeader}</Typography>
    </ListSubheader>);

    return <>
        <Menu
            open={open}
            elevation={3}
            PaperProps={{
                sx: {
                    width: 320,
                    // maxHeight: 800,
                },
            }}
            MenuListProps={{
                dense: false,
                subheader: listSubHeader,
            }}
            {...customDropdownProps}
        >
            <Stack
                sx={{px: 1, pt: 1, pb: 2}}
                spacing={1}
                direction="column"
            >
                <Button
                    variant="text"
                    disableElevation
                    fullWidth
                    startIcon={<Add />}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleCreateClick();
                    }}
                >
                    {createLabel ?? 'Create Item'}
                </Button>
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleSearchChange(event.target.value);
                    }}
                    value={searchValue}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    setSearchValue('');
                                    debounceInputChange('');
                                }}>
                                    {searchValue ? <Close/>: <Search/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Box sx={{
                position: 'relative',
                maxHeight: 5.5 * 56,
                overflow: 'auto',
            }}>
                {loading && <LinearProgress sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                }}/>}
                {items.map((item: CustomDropdownItem) => (
                    <MenuItem
                        key={item.id}
                        sx={{py: 2, px: 3}}
                        onClick={() => {
                            handleItemSelect(item);
                        }}
                    >
                        <ListItemIcon>
                            <FolderOpen />
                        </ListItemIcon>
                        <ListItemText primary={item.title} secondary={item.subtitle} />
                    </MenuItem>
                ))}
                {!loading && items?.length === 0 &&
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <Typography
                            variant="subtitle1"
                            my={3}
                        >
                            Nothing Found
                        </Typography>
                    </Stack>
                }
            </Box>
            <Box sx={{pb: 3}}></Box>
        </Menu>
    </>;
};

export default CustomDropdown;