import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';

export const fetchTenants = createAsyncThunk('tenants/fetch', async (_: void, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.get('api/v1/tenants');

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const createTenant = createAsyncThunk('tenants/create', async (data: {name: string}, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();
        const axios = useAxios(state.auth.accessToken);
        const response = await axios.post('api/v1/tenants', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});
