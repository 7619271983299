export enum ShareableItemTypes {
    DOCUMENT = 'document',
    CATEGORY = 'category',
};

export enum ShareableItemRoles {
    VIEWER = 'viewer',
    EDITOR = 'editor',
    MANAGER = 'manager',
};

export const ShareableRoleOptions: Array<{ value: ShareableItemRoles, label: string, description: string }> = [
    { value: ShareableItemRoles.VIEWER, label: 'Viewer', description: 'Can view' },
    { value: ShareableItemRoles.EDITOR, label: 'Editor', description: 'Can edit' },
    { value: ShareableItemRoles.MANAGER, label: 'Manager', description: 'Can manage' },
];

export interface ShareableItem {
    id: string;
    tenant_id: string;
    user_id: string;
    created_by: string;
    shareable_id: string;
    shareable_type: ShareableItemTypes;
    shareable_name: string;
    shareable_data: any;
    role: ShareableItemRoles;
    created_at: string;
    updated_at: string;
    expires_at: string;
};

export interface ShareableItems extends Array<ShareableItem> {};