import {
    Folder as FolderIcon
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Chip,
    DialogTitle,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Skeleton,
    Stack
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ShareablesList from 'components/ShareablesList';
import UsersAutocomplete from 'components/UsersAutocomplete';
import { ShareableItemRoles, ShareableRoleOptions } from 'interfaces/shareables';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import {
    fetchByShareable,
    shareCategory
} from 'slices/shareablesActions';
import { fetchUsersAll } from 'slices/usersActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


export interface CategoryShareModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const CategoryShareModal = (
    props: CategoryShareModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { category, loading } = useAppSelector((state) => state.category);
    const { users, loading: usersLoading} = useAppSelector((state) => state.users);
    const [shareables, setShereables] = useState<any[]>([]);
    const [peopleSelected, setPeopleSelected] = useState<any[]>([]);
    const [role, setRole] = useState(ShareableRoleOptions[0].value);
    const [isOpen, setIsOpen] = useState(false);

    const onDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsersAll(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    useEffect(() => {
        if (isOpen && category) {
            _fetchByShareable();
        }
    }, [category, isOpen]);

    const _fetchByShareable = useCallback(() => {
        if (!category) return;

        dispatch(fetchByShareable(category?.uuid))
            .unwrap()
            .then((response: any) => {
                setShereables(response.data);
            }).catch(() => {});
    }, [category]);

    const onSubmit = async (data: any) => {
        if (!category) return;
        if (!peopleSelected.length) return;

        let promises: any[] = [];
        for (let index = 0; index < peopleSelected.length; index++) {
            const item = peopleSelected[index];
            promises.push(dispatch(shareCategory({
                uuid: category.uuid,
                user_id: item.id,
                role: role
            })));
        }

        Promise.all(promises).then((responses) => {
            _fetchByShareable();
        }).catch(() => { });
    };

    const handleOnDelete = () => {
        _fetchByShareable();
    };

    const handleRoleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as ShareableItemRoles);
    };

    /**
     * Modal
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        setShereables([]);
        props.onClose && props.onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            titleComponent={
                <DialogTitle>
                    Share folder <Chip color="primary" icon={<FolderIcon fontSize="small" />} sx={{ml: 1}} label={category?.name}/>
                </DialogTitle>
            }
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        color="primary"
                        disableElevation
                        onClick={onSubmit}
                    >
                        Share
                    </LoadingButton>
                </>
            }
        >
            <Box mt={2}>
                {(isOpen)
                    ? <Stack direction="row" spacing={2}>
                        <UsersAutocomplete
                            label="Add people"
                            onDebounce={onDebounce}
                            onSelect={(selected: any)=> {setPeopleSelected(selected)}}
                            loading={usersLoading}
                        />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={role}
                                onChange={handleRoleChange}
                            >
                                {ShareableRoleOptions.map((option: any) => {
                                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                })}
                            </Select>
                            <FormHelperText>{}</FormHelperText>
                        </FormControl>
                    </Stack>
                    : <Skeleton variant="rounded" width={396} height={56} />
                }
                {(shareables.length > 0)
                    && <ShareablesList
                        items={shareables}
                        onDelete={handleOnDelete}
                    />
                }
            </Box>
        </BaseModal>
    </>;
};

export default forwardRef(CategoryShareModal);