import Container from '@mui/material/Container';
import {
    useEffect
} from 'react';
import {
    Outlet,
    useLocation
} from 'react-router-dom';
import {
    getSubdomain,
    redirectToCentral
} from 'utilities/axios';

const UnauthorizedRoute = () => {
    const location = useLocation();
    const subdomain = getSubdomain(true);

    useEffect(() => {
        if (subdomain) {
            redirectToCentral(`signin/?redirect=${encodeURI(subdomain)}`, false, false);
        }
    }, [location]);

    if (subdomain) {
        return <></>;
    }

    return <>
        <Container component="main" disableGutters>
            <Outlet />
        </Container>
    </>;
};

export default UnauthorizedRoute;