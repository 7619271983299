import {
    DeleteForever,
    DeleteSweep,
    Description,
    Folder,
    MoreVert,
    Refresh,
    RestoreFromTrash
} from '@mui/icons-material';
import {
    Box,
    Button,
    Chip,
    Fade,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import {
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowSelectionModel,
    MuiEvent
} from '@mui/x-data-grid';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import DefaultDataGrid from 'components/DefaultDataGrid';
import {
    Categories,
    Category,
    Document,
    Documents,
} from 'interfaces/documents';
import { BaseModalRef } from 'modals/BaseModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import Moment from 'react-moment';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import {
    foreverDeleteCategory,
    restoreDeletedCategory
} from 'slices/categoriesActions';
import {
    setDocument
} from 'slices/documentSlice';
import {
    fetchDeletedDocuments,
    foreverDeleteDocument,
    foreverDeleteTrashed,
    restoreDeletedDocument
} from 'slices/documentsActions';
import {
    reset
} from 'slices/documentsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DocumentsTrashPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { accessToken } = useAppSelector((state) => state.auth);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { loading, list, storageCounters } = useAppSelector((state) => state.documents);
    const [isRowsSelected, setIsRowsSelected] = useState(false);
    const confirmationDeleteForeverAllModal = useRef<BaseModalRef>(null);
    const confirmationDeleteForeverModal = useRef<BaseModalRef>(null);
    const confirmationRestoreModal = useRef<BaseModalRef>(null);
    const documentMarkupModal = useRef<BaseModalRef>(null);
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<Documents>([]);
    const [selectedCategories, setSelectedCategories] = useState<Categories>([]);

    const _fetchDeletedDocuments = useCallback(() => {
        return fetchDeletedDocuments();
    }, [location, accessToken, currentTenant]);

    useEffect(() => {
        dispatch(_fetchDeletedDocuments());
    }, [location, accessToken, currentTenant]);

    const handleBreadcrumbClick = () => {
        dispatch(reset());
        navigate('/documents/trash');
    };

    const handleConfirmDeleteForeverAll = () => {
        dispatch(foreverDeleteTrashed())
            .unwrap()
            .then(() => {
                dispatch(_fetchDeletedDocuments());
            }).catch(() => {});
    };

    const handleConfirmDeleteForever = () => {
        handleDeleteForever();
    };

    const handleDeleteForever = () => {
        let promises: any[] = [];
        selectedCategories.map((category: Category) => {
            promises.push(dispatch(foreverDeleteCategory(category.uuid)));
        });
        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(foreverDeleteDocument(document.id)));
        });

        Promise.all(promises).then((responses) => {
            dispatch(_fetchDeletedDocuments());
        }).catch(() => {});
    };

    const handleConfirmRestore = () => {
        handleRestore();
    };

    const handleRestore = () => {
        let promises: any[] = [];
        selectedCategories.map((category: Category) => {
            promises.push(dispatch(restoreDeletedCategory(category.uuid)));
        });
        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(restoreDeletedDocument(document.id)));
        });

        Promise.all(promises).then((responses) => {
            dispatch(_fetchDeletedDocuments());
        }).catch(() => {});
    };

    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        documentMarkupModal.current?.open();
    };

    const columns: GridColDef[] = [
        {
            flex: 1,
            field: 'name',
            headerName: 'Name',
            renderCell: (params) => {
                if (params.row.type === 'category') {
                    return <><Folder /><Typography sx={{pl: 2}}>{params.row.name}</Typography></>
                } else {
                    return <><Description /><Typography sx={{pl: 2}}>{params.row.name}</Typography></>
                }
            }
        },
        {
            field: 'size_formatted',
            headerName: 'Size',
            width: 150
        },
        {
            field: 'deleted_at',
            headerName: 'Deleted',
            width: 200,
            renderCell: (params) => <Moment date={params.row.deleted_at} format="lll" />
        },
    ];

    const handleSelectRows = (selectedRows: GridRowSelectionModel) => {
        let selectedDocuments: Documents = [];
        let selectedCategories: Categories = [];
        selectedRows.map((rowId: GridRowId) => {
            list.map((item: any) => {
                if (item.type == 'category') {
                    if (item.uuid == rowId) {
                        selectedCategories.push(item);
                    }
                }
                if (item.id == rowId) {
                    selectedDocuments.push(item);
                }
            });
        });

        setSelectedRows(selectedRows);
        setIsRowsSelected(selectedRows.length > 0);
        setSelectedCategories(selectedCategories);
        setSelectedDocuments(selectedDocuments);
    };

    const handleUnselectAll = () => {
        handleSelectRows([]);
    };

    /**
     * Refresh
     */
    const handleRefresh = () => {
        dispatch(_fetchDeletedDocuments());
    };

    return <>
        <Box id="notification-bar"></Box>
        <Grid container sx={{
            position: 'sticky',
            m: 0,
            p: 0,
            top: -1,
            bgcolor: 'white',
            zIndex: 'appBar',
        }}>
            <Grid item xs={12} sx={{py: 3}}>
                <CustomBreadCrumbs
                    title={'Deleted files'}
                    loading={loading}
                    breadcrumbs={[]}
                    onRootClick={() => handleBreadcrumbClick()}
                />
            </Grid>
            <Grid item xs={12} sx={{pb: 3}}>
                <Fade in={isRowsSelected} hidden={!isRowsSelected} appear={false}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Chip
                            label={`${selectedRows.length} items selected`}
                            color="primary"
                            onClick={handleUnselectAll}
                            onDelete={handleUnselectAll}
                        />
                        <Button
                            disableElevation
                            onClick={() => confirmationDeleteForeverModal.current?.open()}
                            startIcon={<DeleteForever />}
                        >
                            Delete Forever
                        </Button>
                        <Button
                            disableElevation
                            onClick={() => confirmationRestoreModal.current?.open()}
                            startIcon={<RestoreFromTrash />}
                        >
                            Restore
                        </Button>
                        <IconButton
                            color="primary"
                            onClick={() => console.log('More')}
                        >
                            <MoreVert />
                        </IconButton>
                    </Stack>
                </Fade>
                <Fade in={!isRowsSelected} hidden={isRowsSelected} appear={false}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{minHeight: '40px'}}>

                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => confirmationDeleteForeverAllModal.current?.open()}
                            startIcon={<DeleteSweep />}
                        >
                            Empty Bin
                        </Button>
                        <Button
                            color="primary"
                            disableElevation
                            onClick={handleRefresh}
                            startIcon={<Refresh />}
                        >
                            Refresh
                        </Button>
                    </Stack>
                </Fade>
            </Grid>
        </Grid>
        <Grid container sx={{m: 0}}>
            <DefaultDataGrid
                rows={list}
                columns={columns}
                loading={loading}
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                checkboxSelection
                rowSelectionModel={selectedRows}
                getRowId={(row: GridRowModel) => {
                    if (row.type === 'category') {
                        return row.uuid;
                    } else {
                        return row.id;
                    }
                }}
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
                    handleSelectRows(rowSelectionModel);
                }}
                onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {
                    if (params.field != 'name') return;
                    if (params.row.type === 'document') {
                        handleDocumentOpen(params.row);
                    }
                }}
            />
        </Grid>

        <DocumentMarkupModal
            ref={documentMarkupModal}
            readonly
        ></DocumentMarkupModal>
        <ConfirmationModal
            ref={confirmationDeleteForeverAllModal}
            text='Are you sure want to delete forever all items?'
            confirmLabel='Delete Forever'
            onConfirm={handleConfirmDeleteForeverAll}
        ></ConfirmationModal>
        <ConfirmationModal
            ref={confirmationDeleteForeverModal}
            text='Are you sure want to delete forever selected items?'
            confirmLabel='Delete Forever'
            onConfirm={handleConfirmDeleteForever}
        ></ConfirmationModal>
        <ConfirmationModal
            ref={confirmationRestoreModal}
            text='Are you sure want to restore selected items?'
            confirmLabel='Restore'
            onConfirm={handleConfirmRestore}
        ></ConfirmationModal>
    </>;
};

export default DocumentsTrashPage;