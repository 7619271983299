import LoadingButton from '@mui/lab/LoadingButton';
import {
    Autocomplete,
    Button,
    Skeleton,
    TextField,
} from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import UsersAutocomplete from 'components/UsersAutocomplete';
import { fetchUsers } from 'slices/usersActions';
import { setParams, clearParams, FiltersState } from 'slices/documentsSlice';

export interface FilterModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const FilterModal = (
    props: FilterModalProps,
    ref: React.Ref<unknown>
) => {
    const { params } = useAppSelector((state) => state.documents);
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const {onSubmit} = props;
    const { handleSubmit } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const formId = 'category_create_form_' + nanoid();
    const { loading: UsersLoading } = useAppSelector((state) => state.users);
    const [usersSelected, setUsersSelected] = useState<any>([]);
    const [type, setType] = useState<any>([]);
    const [modified, setModified] = useState<any>(null);
    const [customModified, setCustomModified] = useState<any>([null, null]);

    useEffect(() => {
        if (isOpen) {
            if (params?.filters) {
                setType(params?.filters?.type?.value || []);
                setUsersSelected(Object.values(params?.filters?.created_by?.value || []));
                setModified(params?.filters?.modified || null);
                if ((params?.filters?.modified_after || params?.filters?.modified_before) && params?.filters?.modified?.id === 'custom') {
                    let modified_after = null;
                    let modified_before = null;
                    if (params?.filters?.modified_after?.value) {
                        let [year, month, day] = params?.filters?.modified_after?.value.split('-');
                        modified_after = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
                    }
                    if (params?.filters?.modified_before?.value) {
                        let [year, month, day] = params?.filters?.modified_before?.value.split('-');
                        modified_before = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
                    }
                    setCustomModified([modified_after, modified_before]);
                }
            }
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const applyFilters = () => {
        var data: FiltersState = {};

        if (type && type.length) {
            data.type = {
                label: 'Type',
                value: type
            };
        }
        if (usersSelected && usersSelected.length) {
            data.created_by = {
                label: 'Created By',
                value: usersSelected
            };
        }
        if (modified) {
            let date = new Date();
            let modified_after, modified_before: Date | null = null;
            switch (modified.id) {
                case 'today':
                    modified_after = date;
                    break;
                case 'yesterday':
                    date.setDate(date.getDate() - 1);
                    modified_before = date;
                    modified_after = date;
                    break;
                case 'last7days':
                    date.setDate(date.getDate() - 7);
                    modified_after = date;
                    break;
                case 'last30days':
                    date.setDate(date.getDate() - 30);
                    modified_after = date;
                    break;
                case 'last90days':
                    date.setDate(date.getDate() - 90);
                    modified_after = date;
                    break;
                case 'custom':
                    if (!customModified[0] || !customModified[1]) {
                        alert('Custom modified date range must have both dates choosen');
                        return;
                    }
                    modified_after = customModified[0].$d;
                    modified_before = customModified[1].$d;
                    break;
            }
            if (modified_after) {
                let year = modified_after.getFullYear();
                let month = String(modified_after.getMonth() + 1).padStart(2, '0');
                let day = String(modified_after.getDate()).padStart(2, '0');
                data.modified_after = {
                    label: 'Modified after',
                    value: `${year}-${month}-${day}`
                };
            }
            if (modified_before) {
                let year = modified_before.getFullYear();
                let month = String(modified_before.getMonth() + 1).padStart(2, '0');
                let day = String(modified_before.getDate()).padStart(2, '0');
                data.modified_before = {
                    label: 'Modified before',
                    value: `${year}-${month}-${day}`
                };
            }
            data.modified = modified;
        }

        dispatch(setParams({ filters: data as FiltersState }));
        onSubmit && onSubmit();
        close();
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    const clearFilters = () => {
        setType([]);
        setUsersSelected([]);
        setModified(null);
        setCustomModified([null, null]);
    };

    const onUsersDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsers(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const handleUsersSelected = (users: any[]) => {
        setUsersSelected(users);
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const typesOptions = [
        { id: 'image',          label: 'Photos & Images' },
        { id: 'pdf',            label: 'PDFs' },
        { id: 'txt',            label: 'Documents' },
        { id: 'xsl',            label: 'Spreadsheet' },
        { id: 'pwp',            label: 'Presentation' },
        { id: 'media',          label: 'Audio / Videos' },
        { id: 'zip',            label: 'Archives (zip)' },
    ];

    const modifiedOptions = [
        { id: 'today',          label: 'Today' },
        { id: 'yesterday',      label: 'Yesterday' },
        { id: 'last7days',      label: 'Last 7 days' },
        { id: 'last30days',     label: 'Last 30 days' },
        { id: 'last90days',     label: 'Last 90 days' },
        { id: 'custom',         label: 'Custom' },
    ];

    return <>
        <BaseModal
            ref={modal}
            title={'Filters'}
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => clearFilters()}
                    >
                        Clear filters
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        color="primary"
                        disableElevation
                    >
                        Apply
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(applyFilters)}>
                    <Autocomplete
                        multiple
                        noOptionsText="No options found..."
                        options={typesOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Type'}
                            />
                        )}
                        value={type}
                        onChange={(event: any, newValue: any) => {
                            setType(newValue);
                        }}
                        isOptionEqualToValue={(option: any, value: any) => {
                            return option.id === value.id;
                        }}
                        sx={{pb: 1}}
                    />
                    <UsersAutocomplete
                        label="Created by"
                        debounceOnOpen={true}
                        dependent={usersSelected}
                        valueSet={usersSelected}
                        onDebounce={onUsersDebounce}
                        onSelect={handleUsersSelected}
                        loading={UsersLoading}
                        sx={{pb: 1}}
                    />
                    <Autocomplete
                        noOptionsText="No options found..."
                        options={modifiedOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Last Modified'}
                            />
                        )}
                        value={modified}
                        onChange={(event: any, newValue: any) => {
                            setModified(newValue);
                        }}
                        isOptionEqualToValue={(option: any, value: any) => {
                            return option.id === value.id;
                        }}
                    />
                    {
                        modified && modified.id === 'custom' &&
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                value={customModified}
                                onChange={(newValue) => setCustomModified(newValue)}
                                sx={{pt: 1}}
                            />
                        </LocalizationProvider>
                    }
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(FilterModal);