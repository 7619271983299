import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'utilities/hooks';

const ProtectedRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    useEffect(() => {
        if (!accessToken) {
            navigate('/signin');
        }
    }, [location, accessToken]);

    if (!accessToken) {
        return <></>;
    }

    return <Outlet />;
};

export default ProtectedRoute;
