import {
    Close as CloseIcon,
    Email as EmailIcon,
    Send as SendIcon,
    Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import {
    Avatar,
    Button,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Invitation, Invitations } from 'interfaces/invitations';
import { TeamMemberRoleOptions } from 'interfaces/teams';
import Moment from 'react-moment';
import { resendInvitation } from 'slices/invitationsActions';
import { removeInvitation, updateInvitationRole } from 'slices/invitationsSlice';
import { useAppDispatch } from 'utilities/hooks';


const InvitationsList = ({
    invitations = [],
    onResend,
}: {
    invitations: Invitations;
    onResend: (invitation: Invitation) => void;
}) => {
    const dispatch = useAppDispatch();

    const handleResend = (invitation: Invitation) => {
        dispatch(resendInvitation(invitation.id));
        onResend && onResend(invitation);
    };

    if (invitations?.length === 0) {
        return <></>;
    }

    return <List sx={{
        '& .MuiListItem-root .hidden': {
            visibility: 'hidden',
        },
        '& .MuiListItem-root:hover .hidden': {
            visibility: 'visible',
        },
    }}>
        {invitations.map((invitation: Invitation, index: number) => {
            return <ListItemButton key={index} disableGutters>
                <ListItem secondaryAction={
                    <IconButton
                        edge="end"
                        className="hidden"
                        onClick={() => {
                            dispatch(removeInvitation(invitation.id));
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }>
                    <ListItemAvatar>
                        <Avatar>
                            {invitation.email ? <EmailIcon /> : <SmartphoneIcon />}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<>
                            {[invitation?.email, invitation?.phone].filter(item => item).join(', ')} <Typography className="hidden" variant="subtitle1" component="span">(<Moment fromNow>{invitation?.code_issued_at}</Moment>)</Typography>
                        </>}
                    />
                    <Button
                        size="medium"
                        variant="text"
                        className="hidden"
                        sx={{mr: 1}}
                        onClick={() => handleResend(invitation)}
                        startIcon={<SendIcon />}
                    >
                        Resend
                    </Button>
                    <FormControl sx={{ minWidth: 120 }}>
                        <Select
                            size="small"
                            value={invitation?.team_role}
                            onChange={(event: SelectChangeEvent) => {
                                dispatch(updateInvitationRole({
                                    id: invitation.id,
                                    team_role: event.target.value,
                                }));
                            }}
                        >
                            {TeamMemberRoleOptions.map((option: any) => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </ListItem>
            </ListItemButton>;
        })}
    </List>;
};

export default InvitationsList;