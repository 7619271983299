import {
    DriveFolderUpload,
    UploadFile
} from '@mui/icons-material';
import {
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { nanoid } from '@reduxjs/toolkit';
import { ProgressItemStatuses } from 'interfaces/progress';
import { BaseModalRef } from 'modals/BaseModal';
import ResolveUploadModal from 'modals/ResolveUploadModal';
import React, {
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { createCategoryNested } from 'slices/categoriesActions';
import { doesDocumentExist, uploadDocument } from 'slices/documentsActions';
import { addItem as addProgressItem } from 'slices/progressSlice';
import { addSnackbar } from 'slices/snackbarsSlice';
import { allowedExtensions } from 'utilities/constants';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

/**
 * Allow to render input attributes
 */
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        directory?: string;
        webkitdirectory?: string;
    }
}

interface AssociativeArrayString {
    [key: string]: string
}

interface AssociativeArrayAny {
    [key: string]: any
}

interface UploadMenuProps extends MenuProps {
    uploadref: any;
}

export interface UploadMenuRef {
    drop: (dropFiles: FileList | null) => void;
};

const UploadMenu = (props: UploadMenuProps & {
    onComplete?: (values: any[]) => void,
}) => {
    const [files, setFiles] = useState<FileList | null>(null);
    const [filesData, setFilesData] = useState<Record<string, any>>([]);
    const [isFolder, setIsFolder] = useState<boolean>(false);
    const { onClose, onComplete, children, ...uploadMenuProps } = props;
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.account);
    const { categoryId } = useParams();

    const filesInputRef = useRef<HTMLInputElement>(null);
    const folderInputRef = useRef<HTMLInputElement>(null);
    const resolveUploadModal = useRef<BaseModalRef>(null);

    useEffect(() => {
        if (isFolder) {
            checkFoldersAndUpload();
        } else {
            checkFilesAndUpload();
        }
    }, [files]);

    const drop = (_files: FileList | null) => {
        if (_files) {
            setFiles(_files);
        }
    };

    useImperativeHandle(props.uploadref, () => ({
        drop: (dropFiles: FileList | null) => drop(dropFiles),
    }));

    const handleFilesClick = (event: React.MouseEvent<HTMLElement>) => {
        filesInputRef.current?.click();
        setIsFolder(false);
        setFilesData([]);

        if (onClose) {
            onClose(event, "backdropClick");
        }
    };

    const handleFolderClick = (event: React.MouseEvent<HTMLElement>) => {
        folderInputRef.current?.click();
        setIsFolder(true);
        setFilesData([]);

        if (onClose) {
            onClose(event, "backdropClick");
        }
    };

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _files: FileList | null = event.target.files;

        if (_files) {
            setFiles(_files);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const target = event.target as HTMLInputElement;
        target.value = '';
    };

    const handleResolve = (forceRename: boolean) => {
        upload(filesData, forceRename);
    };

    const checkFilesAndUpload = () => {
        if (!files) return;

        let _filesData: Record<string, any> = [];
        let promises: any[] = [];

        for (var i = 0; i < files.length; i++) {
            let file = files[i];

            const fileId: string = nanoid();
            const fileExtension = file.name.split('.').pop();

            if (fileExtension) {
                if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                    dispatch(addSnackbar({
                        message: `File '${file.name}' extension is not supported.`,
                        type: 'error'
                    }));
                    continue;
                }
            }

            const data = {
                id: fileId,
                name: file.name,
                extension: fileExtension ? fileExtension.toLowerCase() : null,
                category_id: categoryId ? categoryId : null,
            };

            promises.push(dispatch(doesDocumentExist(data)));

            _filesData[fileId] = Object.assign({
                file: file,
            }, data);
        }
        setFilesData(_filesData);

        Promise.all(promises).then((responses) => {
            let hasConflict = false;
            for (var i = 0; i < responses.length; i++) {
                const response = responses[i];
                if (!response.payload.data) continue;

                hasConflict = true;
            }

            if (hasConflict) {
                resolveUploadModal.current?.open();
            } else {
                upload(_filesData);
            }
        }).catch(() => { });
    };

    const checkFoldersAndUpload = () => {
        if (!files) return;

        let _filesData: Record<string, any> = [];
        let relativePaths: AssociativeArrayString = {};
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            const fileId: string = nanoid();
            const fileExtension = file.name.split('.').pop();
            if (fileExtension) {
                if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                    continue;
                }
            }

            let relativePath = file['webkitRelativePath'];
            relativePath = relativePath.substring(0, relativePath.lastIndexOf('/'));
            relativePaths[fileId] = relativePath;

            _filesData[fileId] = {
                id: fileId,
                file: file,
                path: relativePath,
                name: file.name,
                extension: fileExtension ? fileExtension.toLowerCase() : null,
                category_id: categoryId ? categoryId : null,
            };
        }

        setFilesData(_filesData);

        dispatch(createCategoryNested({
            parent: categoryId ? categoryId : null,
            paths: relativePaths,
        }))
            .unwrap()
            .then((response) => {
                const categoriesMap: AssociativeArrayString = response.data;
                for (const _fileId in _filesData) {
                    _filesData[_fileId]['category_id'] = categoriesMap[_fileId];
                }
                setFilesData(_filesData);
                upload(_filesData);
            }).catch(() => { });
    };

    const upload = (filesData: Record<string, any>, forceRename: Boolean = false) => {
        let promises: any[] = [];

        for (const fileId in filesData) {
            const fileData = filesData[fileId];
            const formData = new FormData();
            formData.append('file', fileData.file);
            formData.append('file_id', fileData.id);
            formData.append('file_props', JSON.stringify({
                category_id: fileData.category_id,
                force_rename: forceRename,
            }));

            dispatch(addProgressItem({
                id: fileData.id,
                data: {
                    name: fileData.name,
                    path: fileData.path,
                    size: fileData.file.size,
                    type: fileData.file.type,
                    lastModified: fileData.file.lastModified,
                },
                status: ProgressItemStatuses.PROCESSING,
            }));

            promises.push(dispatch(uploadDocument(formData)).catch((error) => error));
        }

        Promise.all(promises).then((values) => {
            if (onComplete) {
                onComplete(values);
            }
        }).catch(() => { });
    };

    return <>
        <Menu
            elevation={1}
            keepMounted
            onClose={onClose}
            {...uploadMenuProps}
        >
            <MenuItem onClick={handleFilesClick} sx={{minWidth: 200}}>
                <UploadFile />
                <Typography ml={2}>Files</Typography>
                <input
                    type="file"
                    ref={filesInputRef}
                    onChange={handleFilesChange}
                    onClick={handleClick}
                    accept={allowedExtensions.join(',')}
                    multiple
                    hidden
                />
            </MenuItem>
            <MenuItem onClick={handleFolderClick}>
                <DriveFolderUpload />
                <Typography ml={2}>Folder</Typography>
                <input
                    type="file"
                    ref={folderInputRef}
                    onChange={handleFilesChange}
                    onClick={handleClick}
                    accept={allowedExtensions.join(',')}
                    webkitdirectory="true"
                    directory="true"
                    hidden
                />
            </MenuItem>
            {children}
        </Menu>
        <ResolveUploadModal ref={resolveUploadModal} onResolve={handleResolve} />
    </>;
};

export default UploadMenu;