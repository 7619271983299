import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateEmail, verifyEmail } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
const UpdateEmailForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const { register, reset, handleSubmit } = useForm();
    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const [newEmail, setNewEmail] = useState('');

    useEffect(() => {
        if (user) {
            reset({
                email: user.email,
            });
        }
    }, [user]);

    useEffect(() => {
        reset();
    }, [isCodeRequested]);


    const onSubmit = (data: any) => {
        setNewEmail(data.email);

        dispatch(updateEmail(data))
            .unwrap()
            .then(() => {
                setIsCodeRequested(true);
            }).catch(() => {});
    };

    const onVerify = (data: any) => {
        dispatch(verifyEmail(data))
            .unwrap()
            .then(() => {
                setIsCodeRequested(false);
            }).catch(() => {});
    };

    const handleResend = () => {
        dispatch(updateEmail({
            email: newEmail,
        }))
            .unwrap()
            .then(() => {
                setIsCodeRequested(true);
            }).catch(() => {
                setIsCodeRequested(false);
            });
    };

    const handleCancel = () => {
        setIsCodeRequested(false);
    };

    if (isCodeRequested) {
        return <Box>
            <Stack
                direction="column"
                spacing={1}
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h5">Verify email using code</Typography>
            </Stack>
            <Box
                component="form"
                onSubmit={handleSubmit(onVerify)}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email_verification_code"
                    label="Verification Code"
                    autoComplete="email_verification_code"
                    {...register("email_verification_code", { required: true, minLength: 3, maxLength: 128 })}
                    error={!!error?.errors?.email_verification_code}
                    helperText={error?.errors?.email_verification_code?.[0]}
                />
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                >
                    Save
                </LoadingButton>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle2">Didn't receive the code?<br/>
                    <Link href="#" onClick={handleResend}>Resend code</Link>&nbsp;or&nbsp;
                    <Link href="#" onClick={handleCancel}>Change email address</Link> and try again</Typography>
                </Box>
            </Box>
        </Box>;
    }

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">Update Email address</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                {...register("email", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.email}
                helperText={error?.errors?.email?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disableElevation
                sx={{ mt: 3, mb: 3 }}
            >
                Save
            </LoadingButton>
        </Box>
    </Box>;
};

export default UpdateEmailForm;