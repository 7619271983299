import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Container,
    Grid,
    Link,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    useEffect
} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { signup } from 'slices/authActions';
import { fetchInvitation } from 'slices/invitationsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const RegisterPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { invitationCode } = useParams();
    const { register, handleSubmit, reset } = useForm();
    const { error, loading } = useAppSelector((state) => state.auth);

    const onSignup = async (data: any) => {
        dispatch(signup(data))
            .unwrap()
            .then(() => {
                navigate('/signin');
            }).catch(() => {});
    };

    useEffect(() => {
        if (!invitationCode) return;
        dispatch(fetchInvitation(invitationCode))
            .unwrap()
            .then((res) => {
                reset({
                    invitation: res?.data.id,
                    first_name: res?.data.first_name,
                    last_name: res?.data.last_name,
                    email: res?.data.email,
                    phone: res?.data.phone,
                    smx_id: res?.data.smx_id,
                });
            }).catch(() => {});
    }, [invitationCode]);

    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={2} sx={{ py: 6, px: 4 }}>
                    <Stack
                        direction="column"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>Sitemax</Typography>
                        <Typography variant="subtitle1">Register with Email address</Typography>
                    </Stack>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSignup)}
                        sx={{ mt: 3 }}
                    >
                        <TextField
                            margin="normal"
                            fullWidth
                            id="first_name"
                            label="First Name"
                            autoComplete="first_name"
                            autoFocus
                            {...register("first_name", { required: true, minLength: 3, maxLength: 128 })}
                            error={!!error?.errors?.first_name}
                            helperText={error?.errors?.first_name?.[0]}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            autoComplete="last_name"
                            {...register("last_name", { required: true, minLength: 3, maxLength: 128 })}
                            error={!!error?.errors?.last_name}
                            helperText={error?.errors?.last_name?.[0]}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            autoComplete="email"
                            {...register("email", { required: true, minLength: 3, maxLength: 128 })}
                            error={!!error?.errors?.email}
                            helperText={error?.errors?.email?.[0]}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="phone"
                            label="Phone Number"
                            autoComplete="phone"
                            {...register("phone", { required: true, minLength: 9, maxLength: 16 })}
                            error={!!error?.errors?.phone}
                            helperText={error?.errors?.phone?.[0]}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            id="password"
                            {...register("password", { required: true, minLength: 6, maxLength: 32 })}
                            error={!!error?.errors?.password}
                            helperText={error?.errors?.password?.[0]}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            id="password_confirmation"
                            {...register("password_confirmation")}
                            error={!!error?.errors?.password_confirmation}
                            helperText={error?.errors?.password_confirmation?.[0]}
                            required
                        />
                        <input
                            type="hidden"
                            id="smx_id"
                            {...register("smx_id")}
                        />
                        <input
                            type="hidden"
                            id="invitation"
                            {...register("invitation")}
                        />
                        <Box sx={{ mt:1, textAlign: 'center' }}>
                            <Typography variant="subtitle2">By clicking Register you are agreeing to the<br/><Link href="/terms" target="_blank">Terms and Conditions</Link></Typography>
                        </Box>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 3 }}
                        >
                            Register
                        </LoadingButton>
                    </Box>
                    <Box sx={{ mt:1, textAlign: 'center' }}>
                        <Link href="/signin">Already have an account?</Link>
                    </Box>
                </Paper>
            </Container>
        </Grid>
    </>;
};

export default RegisterPage;