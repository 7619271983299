import {
    Autocomplete,
    ListItem,
    ListItemText,
    TextField
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    useEffect,
    useMemo,
    useState
} from 'react';


export interface ContactsAutocompleteProps {
    label: string,
    error?: boolean,
    helperText?: string,
    debounceOnOpen?: boolean,
    onChange: (value: any) => void,
    onDebounce: (value: string, callback: (value: any) => void) => void,
};

const ContactsAutocomplete = (
    props: ContactsAutocompleteProps & {
        [prop: string]: any;
    }
) => {
    let {
        label,
        error,
        helperText,
        debounceOnOpen,
        onChange,
        onDebounce,
        ...autocompleteProps
    } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any[]>([]);
    const [value, setValue] = useState<any>(null);
    const [inputValue, setInputValue] = useState<any>('');

    const debounceInputChange = useMemo(() => {
        return debounce((newValue: string) => {
            onDebounce(newValue, (values: any[]) => {
                setOptions(values);
            });
        }, 400);
    }, []);

    useEffect(() => {
        if (!open) return;
        if (!debounceOnOpen) return;

        debounceInputChange(inputValue);
    }, [open]);

    return <Autocomplete
        clearOnBlur={false}
        clearOnEscape={false}
        fullWidth
        disableCloseOnSelect
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        noOptionsText="No options found..."
        options={options}
        filterOptions={(options: any) => options}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option: any, value: any) => {
            return option.id === value.id;
        }}
        renderOption={(props: any, option: any) => (
            <ListItem {...props} key={option.id}>
                <ListItemText
                    primary={option.name}
                />
            </ListItem>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                error={error}
                helperText={helperText}
            />
        )}
        onChange={(event: any, newValue: any) => {
            onChange(newValue);
            setValue(newValue);
        }}
        onInputChange={(event: any, newValue: any) => {
            setInputValue(newValue);

            if (!newValue) {
                return;
            }
            debounceInputChange(newValue);
        }}
        value={value}
        inputValue={inputValue}
        {...autocompleteProps}
    />;
};

export default ContactsAutocomplete;