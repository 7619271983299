import {
    Button,
    Container,
    Stack
} from '@mui/material';
import UpdatePhoneForm from 'components/UpdatePhoneForm';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAccount } from 'slices/accountActions';
import { useAppDispatch } from 'utilities/hooks';

const PhoneUpdatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    return <>
        <Container component="main" maxWidth="xs">
            <Stack spacing={5} sx={{ mt: 3 }}>
                <UpdatePhoneForm />
                <Button
                    onClick={() => {
                        navigate('/profile');
                    }}
                    fullWidth
                    variant="outlined"
                    size="large"
                    disableElevation
                    sx={{ mt: 3, mb: 3 }}
                >
                    Back
                </Button>
            </Stack>
        </Container>
    </>;
};

export default PhoneUpdatePage;