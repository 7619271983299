import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import DocumentsPage from 'pages/DocumentsPage';
import DocumentsSharedWithMePage from 'pages/DocumentsSharedWithMePage';
import DocumentsTrashPage from 'pages/DocumentsTrashPage';
import EmailUpdatePage from 'pages/EmailUpdatePage';
import ForgotPage from 'pages/ForgotPage';
import IndexPage from 'pages/IndexPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import NotFoundPage from 'pages/NotFoundPage';
import PasswordUpdatePage from 'pages/PasswordUpdatePage';
import PhoneUpdatePage from 'pages/PhoneUpdatePage';
import ProfilePage from 'pages/ProfilePage';
import RegisterPage from 'pages/RegisterPage';
import SigninPage from 'pages/SigninPage';
import VerifyPage from 'pages/VerifyPage';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import ProtectedRoute from 'routes/ProtectedRoute';
import RedirectRoute from 'routes/RedirectRoute';
import SideLayout from 'routes/SideLayout';
import TopLayout from 'routes/TopLayout';
import UnauthorizedRoute from 'routes/UnauthorizedRoute';
import { removeSnackbar } from 'slices/snackbarsSlice';
import { useAppDispatch } from 'utilities/hooks';


const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#08172b',
            dark: '#000f22',
            light: '#243245',
        },
        secondary: {
            main: '#ffcc32',
        },
    },
    shape: {
        borderRadius: 6,
    },
    // shadows: shadows.map(() => 'none') as Shadows,
    // transitions: {
    //     create: () => 'none',
    // },
    typography: {
        fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
});

function App() {
    const dispatch = useAppDispatch();
    return (
        <>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                preventDuplicate
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                action={(key) => (
                    <Button key={key} onClick={() => { dispatch(removeSnackbar(key))}} sx={{color: 'white'}}>
                        Dismiss
                    </Button>
                )}
            >
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Routes>
                            <Route path="redirect" element={<RedirectRoute />} />
                            <Route element={<UnauthorizedRoute />}>
                                <Route path="signin" element={<SigninPage />} />
                                <Route path="register" element={<RegisterPage />} />
                                <Route path="register/:invitationCode" element={<RegisterPage />} />
                                <Route path="forgot" element={<ForgotPage />} />
                            </Route>
                            <Route element={<ProtectedRoute />}>
                                <Route element={<TopLayout />}>
                                    <Route path="/" element={<IndexPage />} />
                                    <Route path="profile">
                                        <Route index element={<ProfilePage />} />
                                        <Route path="verify" element={<VerifyPage />} />
                                        <Route path="email/update" element={<EmailUpdatePage />} />
                                        <Route path="password/update" element={<PasswordUpdatePage />} />
                                        <Route path="phone/update" element={<PhoneUpdatePage />} />
                                    </Route>
                                    <Route path="applications" element={<IntegrationsPage />} />
                                    <Route path="documents" element={<SideLayout />}>
                                        <Route index element={<DocumentsPage />} />
                                        <Route path="p/:teamId" element={<DocumentsPage />} />
                                        <Route path="t/:teamId" element={<DocumentsPage hasProjects={false} />} />
                                        <Route path="trash" element={<DocumentsTrashPage />} />
                                        <Route path="shared" element={<DocumentsSharedWithMePage />} />
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </ThemeProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </>
    );
}

export default App;
