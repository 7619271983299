import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRejectedValues, useAxios } from '../utilities/axios';

export const createCategory = createAsyncThunk('category/create', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);
        const response = await axios.post('api/v1/categories', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const createCategoryNested = createAsyncThunk('category/create/nested', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;

        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);
        const response = await axios.post('api/v1/categories/nested', data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetch = createAsyncThunk('category/fetch', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.get(`api/v1/categories/${id}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchCategories = createAsyncThunk('category/list', async (parent_id: any | null = null, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        let params: any = {};
        if (parent_id) params.parent_id = parent_id;

        const response = await axios.get('api/v1/list/categories', { params: params });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchCategoriesMap = createAsyncThunk('category/map', async (parent_id: any | null = null, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        let params: any = {};
        if (parent_id) params.parent_id = parent_id;

        const response = await axios.get('api/v1/list/categories', { params: params });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const renameCategory = createAsyncThunk('category/rename', async (data: { id: string, name: string }, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/categories/${data.id}/rename`, data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const moveCategory = createAsyncThunk('category/move', async (data: { id: string, parent: string|null }, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/categories/${data.id}/move`, data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const softDeleteCategory = createAsyncThunk('category/softDelete', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/categories/${id}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const foreverDeleteCategory = createAsyncThunk('posts/foreverDeleteCategory', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/categories/${id}/forever`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const restoreDeletedCategory = createAsyncThunk('posts/restoreDeletedCategory', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/categories/${id}/restore`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});
