import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchIntegrations, revokeIntegration } from 'slices/integrationsActions';

interface IntegrationsState {
    resolved: boolean;
    error: any | null;
    loading: boolean;
    integrations: any[] |null;
};

export const initialState: IntegrationsState = {
    loading: false,
    error: null,
    resolved: false,
    integrations: [],
};

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchIntegrations.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchIntegrations.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;
            state.integrations = action.payload.data;
        })
        builder.addCase(fetchIntegrations.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.integrations = [];
            state.error = action.payload.data;
        })

        builder.addCase(revokeIntegration.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(revokeIntegration.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;
        })
        builder.addCase(revokeIntegration.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    },
});

export const {
    reset,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;