import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchDeletedDocuments,
    fetchDocuments,
    fetchSharedDocuments,
    fetchStorageCounters,
    foreverDeleteDocument,
    foreverDeleteTrashed,
    restoreDeletedDocument,
    softDeleteDocument,
    uploadDocument
} from './documentsActions';

interface AssociativeArray {
    [key: string]: any
}

interface Filter {
    [key: string]: any;
    label: string;
    value: string | null;
}

export interface FiltersState {
    [key: string]: any;
    type?: Filter;
    created_by?: Filter;
    modified_before?: Filter;
    modified_after?: Filter;
    modified?: any;
};

interface ParamsState {
    [key: string]: any;
    query?: string | null;
    parent_id?: string | null;
    filters?: FiltersState | null;
}

interface DocumentsState {
    list: any[];
    breadcrumbs: any[];
    shareables: any[];
    teams: AssociativeArray;
    users: AssociativeArray;
    storageCounters: AssociativeArray;
    loading: boolean;
    error: any | null;
    params: ParamsState;
};

export const initialState: DocumentsState = {
    list: [],
    breadcrumbs: [],
    shareables: [],
    teams: [],
    users: [],
    storageCounters: [],
    loading: false,
    error: null,
    params: {},
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        reset: () => initialState,
        setParams: (state, action: PayloadAction<ParamsState>) => {
            let preparams = JSON.parse(JSON.stringify(state.params));
            Object.keys(action.payload).forEach(key => {
                preparams[key] = action.payload[key];
            });
            state.params = preparams;
        },
        deleteFilter: (state, action: PayloadAction<any>) => {
            let preparams = JSON.parse(JSON.stringify(state.params));
            if (action.payload.id && preparams.filters[action.payload.key]) {
                preparams.filters[action.payload.key].value.forEach((element: any, index: any) => {
                    if (element.id === action.payload.id) {
                        preparams.filters[action.payload.key].value.splice(index, 1);
                    }
                });
            } else {
                delete preparams.filters[action.payload.key];
                if (action.payload.key == 'modified_before' || action.payload.key == 'modified_after') {
                    delete preparams.filters['modified'];
                    delete preparams.filters['modified_before'];
                    delete preparams.filters['modified_after'];
                }
            }
            state.params = preparams;
        },
        clearParams: (state) => {
            state.params = {};
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchStorageCounters.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchStorageCounters.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload) return;

            state.storageCounters = action.payload.data;
        })
        builder.addCase(fetchStorageCounters.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })

        builder.addCase(fetchDocuments.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload) return;

            state.list = action.payload.data.list;
            state.teams = action.payload.data.teams;
            state.users = action.payload.data.users;
            state.shareables = action.payload.data.shareables;
            state.breadcrumbs = action.payload.data.breadcrumbs;
        })
        builder.addCase(fetchDocuments.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
        builder.addCase(uploadDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(uploadDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(uploadDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(softDeleteDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(softDeleteDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(softDeleteDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchSharedDocuments.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchSharedDocuments.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            state.breadcrumbs = action.payload.data.breadcrumbs;
            state.list = action.payload.data.list;
        })
        builder.addCase(fetchSharedDocuments.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(fetchDeletedDocuments.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDeletedDocuments.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;
            state.breadcrumbs = action.payload.data.breadcrumbs;
            state.list = action.payload.data.list;
        })
        builder.addCase(fetchDeletedDocuments.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(foreverDeleteDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(foreverDeleteDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(foreverDeleteDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(foreverDeleteTrashed.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(foreverDeleteTrashed.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(foreverDeleteTrashed.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(restoreDeletedDocument.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(restoreDeletedDocument.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(restoreDeletedDocument.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { reset, setParams, clearParams, deleteFilter } = documentsSlice.actions;
export default documentsSlice.reducer;
