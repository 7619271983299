import axios from 'axios';
import { Team } from 'interfaces/teams';
import { Tenant } from 'interfaces/tenants';

const instance = axios.create();

export function getAppDomain(): string | undefined {
    return process.env.REACT_APP_DOMAIN;
};

export function hasSubdomain(): boolean {
    const locationHost: string = window.location.host;
    const appDomain: string | undefined = process.env.REACT_APP_DOMAIN;
    if (appDomain) {
        if (locationHost.indexOf(appDomain) <= 0) return false;
    }

    return true;
};

export function getSubdomain(withProtocol: boolean = false): string | null {
    const locationHost: string = window.location.host;
    const appDomain: string | undefined = process.env.REACT_APP_DOMAIN;
    if (appDomain) {
        if (locationHost.indexOf(appDomain) <= 0) return null;
    }

    if (withProtocol) {
        return `${window.location.protocol}//${locationHost}`;
    }

    return locationHost;
};

export function getTenant(): string | null {
    const searchParams = new URLSearchParams(window.location.search);

    let tenant: string | null = null;
    if (localStorage.getItem('currentTenant')) tenant = localStorage.getItem('currentTenant');
    if (searchParams.get('tenant')) tenant = searchParams.get('tenant');

    return tenant;
};

export function getAccessToken(): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    const token: string | null = searchParams.get('token');
    if (token) {
        localStorage.setItem('accessToken', token);

        return token;
    }

    return localStorage.getItem('accessToken');
};

export function getURLSearchParam(param: string): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get(param);

    return paramValue ? decodeURI(paramValue) : paramValue;
};

function redirectTo(url: string, isBlank: boolean): void {
    if (isBlank) {
        window.open(url, '_blank');
    } else {
        window.location.replace(url);
    }
};

export function redirectToCentral(route: null | string, withToken: boolean, isBlank: boolean): void {
    let url = `${window.location.protocol}//${process.env.REACT_APP_DOMAIN}`;
    if (route) {
        url = `${url}/${route}`;
    }
    if (withToken) {
        const token = getAccessToken();
        url = `${url}/redirect/?token=${token}`;
    }

    redirectTo(url, isBlank);
};

export function redirectToTenant(tenant: Tenant, isBlank: boolean): void {
    const token = getAccessToken();
    if (!token) return;

    const url = `${window.location.protocol}//${tenant.domain}/redirect/?token=${token}`;

    redirectTo(url, isBlank);
};

export function redirectToHost(host: string, isBlank: boolean): void {
    const token = getAccessToken();
    if (!token) return;

    const url = `${host}/redirect/?token=${token}`;

    redirectTo(url, isBlank);
};

export function getRejectedValues(error: any): any {
    return {
        status: error.response.status,
        data: error.response.data,
    };
};

export function useAxios(token?: string | null, tenant?: Tenant | null, team?: Team | null): any {
    if (token) {
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
        delete(instance.defaults.headers.common['Authorization']);
    }

    if (team) {
        instance.defaults.headers.common['X-Tenant-Team'] = team.id;
    } else {
        delete(instance.defaults.headers.common['X-Tenant-Team']);
    }

    let port = '';
    if (process.env.REACT_APP_API_PORT && process.env.REACT_APP_API_PORT.length > 0) port = ':' + process.env.REACT_APP_API_PORT;

    if (tenant) {
        /**
         * Nginx routes don't support {tenant}/api paths. Solve nginx config challenge before use that.
         *
         * if (hasSubdomain()) {
         *     instance.defaults.baseURL = `${window.location.protocol}//${tenant.domain}`;
         * } else {
         *     instance.defaults.baseURL = `${window.location.protocol}//${process.env.REACT_API_DOMAIN}/${tenant.name}`;
         * }
         */
        instance.defaults.baseURL = `${window.location.protocol}//${tenant.domain}${port}`;
    } else {
        instance.defaults.baseURL = `${window.location.protocol}//${process.env.REACT_APP_API_DOMAIN}${port}`;
    }

    return instance;
};