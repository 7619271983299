import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Team, Teams } from 'interfaces/teams';
import {
    fetchDefaultTeam,
    fetchTeams,
    searchTeams
} from './teamsActions';

interface TeamsState {
    loading: boolean;
    error: any | null;
    teams: Teams | null;
    searchTeams: Teams | null;
    projectTeams: Teams | null;
    currentTeam: Team | null;
    defaultTeam: Team | null;
    // guestTeam: Team | null;
};

export const initialState: TeamsState = {
    loading: false,
    error: null,
    teams: [],
    searchTeams: [],
    projectTeams: [],
    currentTeam: null,
    defaultTeam: null,
    // guestTeam: null,
};

const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        reset: () => initialState,
        setCurrentTeamById: (state, action: PayloadAction<string>) => {
            const team = state.teams?.find((team: Team) => {
                return team.id === action.payload
            });

            if (team) {
                state.currentTeam = team;
            }
        },
        setCurrentTeam: (state, action: PayloadAction<Team>) => {
            state.currentTeam = action.payload;
        },
        resetCurrentTeam: (state) => {
            state.currentTeam = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTeams.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchTeams.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            if (!action.payload) return;
            if (!action.payload.data) return;

            state.teams = action.payload.data.filter((team: Team) => {
                return team.type === 'custom' && team.entity_id === null;
            });

            state.projectTeams = action.payload.data.filter((team: Team) => {
                return team.type === 'custom' && team.entity_id !== null;
            });

            const defaultTeam = state.teams?.find((team: Team) => {
                return team.type == 'default';
            });
            if (defaultTeam) {
                state.defaultTeam = defaultTeam;
            }

            const currentTeam = state.teams?.find((team: Team) => {
                return team.id === state.currentTeam?.id
            });
            if (currentTeam) {
                state.currentTeam = currentTeam;
            }
        })
        builder.addCase(fetchTeams.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.teams = [];
            state.error = action.payload.data;
        })

        builder.addCase(searchTeams.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(searchTeams.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.searchTeams = action.payload.data.filter((team: Team) => {
                return team.type === 'custom' && team.entity_id === null;
            });
        })
        builder.addCase(searchTeams.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.searchTeams = [];
            state.error = action.payload.data;
        })

        builder.addCase(fetchDefaultTeam.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchDefaultTeam.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = null;

            state.defaultTeam = action.payload.data;
        })
        builder.addCase(fetchDefaultTeam.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })

    },
});

export const {
    reset,
    resetCurrentTeam,
    setCurrentTeam,
    setCurrentTeamById,
} = teamsSlice.actions;
export default teamsSlice.reducer;