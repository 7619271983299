import {
    Add,
    Close,
    ContentCopy,
    DeleteSweep,
    Description,
    DriveFileMove,
    DriveFileRenameOutline,
    ExpandMore,
    FilterList,
    Folder,
    MoreVert,
    OpenWith,
    People,
    Refresh,
    RestorePage,
    Share,
    SyncAlt,
    Upload
} from '@mui/icons-material';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    ButtonGroup,
    Chip,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowParams,
    GridRowSelectionModel,
    MuiEvent
} from '@mui/x-data-grid';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import CustomDropdown from 'components/CustomDropdown';
import DefaultDataGrid from 'components/DefaultDataGrid';
import ProgressCard, { ProgressCardRef } from 'components/ProgressCard';
import UploadMenu, { UploadMenuRef } from 'components/UploadMenu';
import {
    Categories,
    Category,
    Document,
    Documents,
} from 'interfaces/documents';
import {
    EntityContext,
    ProjectsContext,
    TeamsContext,
} from 'interfaces/teams';
import { BaseModalRef } from 'modals/BaseModal';
import CategoriesTreeModal from 'modals/CategoriesTreeModal';
import CategoryCreateModal from 'modals/CategoryCreateModal';
import CategoryRenameModal from 'modals/CategoryRenameModal';
import CategoryShareModal from 'modals/CategoryShareModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import DefaultTeamMembersUpdateModal from 'modals/DefaultTeamMembersUpdateModal';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import DocumentRenameModal from 'modals/DocumentRenameModal';
import DocumentShareModal from 'modals/DocumentShareModal';
import DocumentVersionsModal from 'modals/DocumentVersionsModal';
import FilterModal from 'modals/FilterModal';
import GuestTeamCreateModal from 'modals/GuestTeamCreateModal';
import GuestTeamMembersUpdateModal from 'modals/GuestTeamMembersUpdateModal';
import ProjectTeamCreateModal from 'modals/ProjectTeamCreateModal';
import TeamCreateModal from 'modals/TeamCreateModal';
import TeamMembersUpdateModal from 'modals/TeamMembersUpdateModal';
import TeamRenameModal from 'modals/TeamRenameModal';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import Moment from 'react-moment';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchCategories,
    moveCategory,
    softDeleteCategory
} from 'slices/categoriesActions';
import {
    setExcluded
} from 'slices/categoriesSlice';
import {
    setCategory
} from 'slices/categorySlice';
import {
    setDocument
} from 'slices/documentSlice';
import {
    copyDocument,
    fetchDocuments,
    moveDocument,
    softDeleteDocument
} from 'slices/documentsActions';
import {
    deleteFilter,
    setParams
} from 'slices/documentsSlice';
import {
    fetchIntegrations,
    syncIntegration
} from 'slices/integrationsActions';
import {
    fetchProjects
} from 'slices/projectsActions';
import {
    resetCurrentProject,
    setCurrentProject
} from 'slices/projectsSlice';
import {
    fetchTeams,
    softDeleteTeam,
} from 'slices/teamsActions';
import {
    resetCurrentTeam,
    setCurrentTeam,
    setCurrentTeamById,
} from 'slices/teamsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DocumentsPage = ({
    hasProjects = true,
}: {
    hasProjects?: boolean
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        teamId,
        projectId,
        categoryId,
        documentId
    } = useParams();

    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { teams: teamsList, projectTeams: projectTeamsList, currentTeam } = useAppSelector((state) => state.teams);
    const { projects: projectsList, currentProject, loading: projectsLoading } = useAppSelector((state) => state.projects);
    const { integrations } = useAppSelector((state) => state.integrations);
    const {
        list,
        users,
        teams,
        breadcrumbs,
        loading,
        params,
    } = useAppSelector((state) => state.documents);

    const documentMarkupModal = useRef<BaseModalRef>(null);
    const documentRenameModal = useRef<BaseModalRef>(null);
    const documentShareModal = useRef<BaseModalRef>(null);
    const documentVersionsModal = useRef<BaseModalRef>(null);
    const categoryCreateModal = useRef<BaseModalRef>(null);
    const categoryRenameModal = useRef<BaseModalRef>(null);
    const categoryShareModal = useRef<BaseModalRef>(null);
    const confirmBulkDeleteModal = useRef<BaseModalRef>(null);
    const confirmRowDeleteModal = useRef<BaseModalRef>(null);
    const confirmTeamDeleteModal = useRef<BaseModalRef>(null);
    const copyBulkModal = useRef<BaseModalRef>(null);
    const copyRowModal = useRef<BaseModalRef>(null);
    const moveBulkModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);
    const progressCard = useRef<ProgressCardRef>(null);
    const teamCreateModal = useRef<BaseModalRef>(null);
    const teamMembersUpdateModal = useRef<BaseModalRef>(null);
    const teamRenameModal = useRef<BaseModalRef>(null);
    const guestTeamCreateModal = useRef<BaseModalRef>(null);
    const guestTeamMembersUpdateModal = useRef<BaseModalRef>(null);
    const projectTeamCreateModal = useRef<BaseModalRef>(null);
    const defaultTeamMembersUpdateModal = useRef<BaseModalRef>(null);
    const filterModal = useRef<BaseModalRef>(null);
    const uploadMenuRef = useRef<UploadMenuRef>(null);

    const [teamMenuAnchor, setTeamMenuAnchor] = useState<null | HTMLElement>(null);
    const [uploadMenuAnchor, setUploadMenuAnchor] = useState<null | HTMLElement>(null);
    const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);
    const [projectsMenuAnchor, setProjectsMenuAnchor] = useState<null | HTMLElement>(null);
    const [rowMenuItem, setRowMenuItem] = useState<null | any>(null);
    const [currentEntity, setCurrentEntity] = useState<null | any>(null);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [countRowsSelected, setCountRowsSelected] = useState(0);
    const [isRowsSelected, setIsRowsSelected] = useState(false);
    const [isSingleSelected, setIsSingleSelected] = useState(false);
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [isDocumentSelected, setIsDocumentSelected] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState<Documents>([]);
    const [selectedCategories, setSelectedCategories] = useState<Categories>([]);
    const [categorySelected, setCategorySelected] = useState<null | Category>(null);
    const [isRowMenuItemCategory, setIsRowMenuItemCategory] = useState(false);

    /**
     * Go to folder
     */
    useEffect(() => {
        if (!teamId) return;
        dispatch(setCurrentTeamById(teamId));

        if (!hasProjects) return;
        const team = projectTeamsList?.find((team: any) => {
            return team.id === teamId;
        });

        if (!team) return;
        const project = projectsList?.find((project: any) => {
            return project.id === team.entity_id;
        });

        if (!project) return;
        dispatch(setCurrentProject(project));
    }, [teamId, teamsList, projectTeamsList, projectsList]);


    const handleSetCurrentTeamByProjectId = (projectId?: string) => {
        const team = projectTeamsList?.find((team: any) => {
            return team.entity_id === projectId;
        });

        if (!team) return;
        navigateTo(team.id);
        dispatch(setCurrentTeam(team));
    };

    useEffect(() => {
        dispatch(fetchTeams());
        dispatch(fetchProjects(null));
        dispatch(resetCurrentTeam());
        dispatch(resetCurrentProject());
    }, [currentTenant]);

    useEffect(() => {
        dispatch(fetchDocuments(categoryId));
        dispatch(fetchCategories(null));
        dispatch(fetchIntegrations());
    }, [currentTenant, currentTeam]);

    const navigateTo = (teamId?: string | null, categoryId?: string | null, documentId?: string) => {
        let to: string = '/documents';

        if (teamId) {
            if (hasProjects) {
                to += `/p/${teamId}`;
            } else {
                to += `/t/${teamId}`;
            }
        }
        // if (categoryId) to += `/f/${categoryId}`;
        // if (documentId) to += `/d/${documentId}`;

        navigate(to);
    };

    const handleCategory = (category: Category) => {
        if (category.id !== categorySelected?.id) {
            dispatch(fetchDocuments(category.uuid));
            setCategorySelected(category);
            navigateTo(currentTeam?.id, category.uuid);
        }
    };

    /**
     * Upload
     */
    const handleUploadMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setUploadMenuAnchor(event.currentTarget);
    };

    const handleUploadMenuClose = () => {
        setUploadMenuAnchor(null);
    };

    const handleUploadMenuComplete = (values: any[]) => {
        dispatch(fetchDocuments(categoryId));
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        uploadMenuRef.current?.drop(acceptedFiles);
    }, []);

    const { getRootProps, isDragActive } = useDropzone({ onDrop });

    /**
     * Selection
     */
    const handleSelectRows = (selectedRows: GridRowSelectionModel) => {
        let selectedDocuments: Documents = [];
        let selectedCategories: Categories = [];
        selectedRows.map((rowId: GridRowId) => {
            list.map((item: any) => {
                if (item.type === 'category') {
                    if (item.uuid === rowId) {
                        selectedCategories.push(item);
                    }
                }
                if (item.id === rowId) {
                    selectedDocuments.push(item);
                }
            });
        });

        setRowSelectionModel(selectedRows);
        setCountRowsSelected(selectedRows.length);
        setIsRowsSelected(selectedRows.length > 0);
        setIsSingleSelected(selectedRows.length === 1);
        setSelectedCategories(selectedCategories);
        setIsCategorySelected(selectedCategories.length > 0);
        setSelectedDocuments(selectedDocuments);
        setIsDocumentSelected(selectedDocuments.length > 0);
    };

    const handleUnselectAll = () => {
        handleSelectRows([]);
    };

    /**
     * Share
     */
    const handleShare = () => {
        if (!isSingleSelected) return;

        if (isCategorySelected) {
            dispatch(setCategory(selectedCategories[0]));
            categoryShareModal.current?.open();
        }
        if (isDocumentSelected) {
            dispatch(setDocument(selectedDocuments[0]));
            documentShareModal.current?.open();
        }
    };

    const handleRowShare = () => {
        setRowMenuAnchor(null);
        if (rowMenuItem.type === 'category') {
            dispatch(setCategory(rowMenuItem));
            categoryShareModal.current?.open();
        } else {
            dispatch(setDocument(rowMenuItem));
            documentShareModal.current?.open();
        }
        setCurrentEntity(rowMenuItem);
    };

    const handleRowGuestTeam = () => {
        setRowMenuAnchor(null);
        let team: any = null;
        if (rowMenuItem.type === 'category') {
            team = teams[rowMenuItem?.uuid];
        } else {
            team = teams[rowMenuItem?.id];
        }
        setCurrentEntity(rowMenuItem);

        if (team) {
            guestTeamMembersUpdateModal.current?.open();
        } else {
            guestTeamCreateModal.current?.open();
        }
    };

    /**
     * Markup
     */
    const handleRowOpen = () => {
        setRowMenuAnchor(null);
        dispatch(setDocument(rowMenuItem));
        documentMarkupModal.current?.open();
    };

    const handleDocumentOpen = (document: Document) => {
        dispatch(setDocument(document));
        documentMarkupModal.current?.open();
        navigateTo(currentTeam?.id, categorySelected?.uuid, document.id)
    };

    const handleDocumentOpenProgress = (item: any) => {
        if (item.data) {
            handleDocumentOpen(item.data as Document);
        }
    };

    /**
     * Versions
     */
    const handleVersions = () => {
        if (isDocumentSelected) {
            dispatch(setDocument(selectedDocuments[0]));
            documentVersionsModal.current?.open();
        }
    };

    const handleRowVersions = () => {
        setRowMenuAnchor(null);
        dispatch(setDocument(rowMenuItem));
        documentVersionsModal.current?.open();
    };

    /**
     * Move
     */
    const handleBulkMove = () => {
        dispatch(setExcluded(selectedCategories));
        moveBulkModal.current?.open();
    };

    const handleBulkMoveSubmit = (destination: Category | null) => {
        let promises: any[] = [];
        if (isCategorySelected) {
            selectedCategories.map((category: Category) => {
                promises.push(dispatch(moveCategory({
                    id: category.uuid,
                    parent: destination?.uuid || null,
                })));
            });
        }
        if (isDocumentSelected) {
            selectedDocuments.map((document: Document) => {
                promises.push(dispatch(moveDocument({
                    id: document.id,
                    category_id: destination?.uuid || null,
                })));
            });
        }

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));
        }).catch(() => { });
    };

    const handleRowMove = () => {
        setRowMenuAnchor(null);
        if (!rowMenuItem) return;
        if (rowMenuItem.type === 'category') {
            dispatch(setExcluded([rowMenuItem]));
        }

        moveRowModal.current?.open();
    };

    const handleRowMoveSubmit = (destination: Category | null) => {
        let promises: any[] = [];

        if (rowMenuItem.type === 'category') {
            promises.push(dispatch(moveCategory({
                id: rowMenuItem.uuid,
                parent: destination?.uuid || null,
            })));
        } else {
            promises.push(dispatch(moveDocument({
                id: rowMenuItem.id,
                category_id: destination?.uuid || null,
            })));
        }

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));
        }).catch(() => { });
    };

    /**
     * Copy
     */
    const handleBulkCopy = () => {
        copyBulkModal.current?.open();
    };

    const handleBulkCopySubmit = (destination: Category | null) => {
        if (!isDocumentSelected) return;

        let promises: any[] = [];

        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(copyDocument({
                id: document.id,
                category_id: destination?.uuid || null,
            })));
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));
        }).catch(() => { });
    };

    const handleRowCopy = () => {
        setRowMenuAnchor(null);
        if (!rowMenuItem) return;

        copyRowModal.current?.open();
    };

    const handleRowCopySubmit = (destination: Category | null) => {
        let promises: any[] = [];

        promises.push(dispatch(copyDocument({
            id: rowMenuItem.id,
            category_id: destination?.uuid || null,
        })));

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));
        }).catch(() => { });
    };

    /**
     * Delete
     */
    const handleBulkDelete = () => {
        confirmBulkDeleteModal.current?.open();
    };

    const handleConfirmBulkDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        selectedCategories.map((category: Category) => {
            promises.push(dispatch(softDeleteCategory(category.uuid)));
        });
        selectedDocuments.map((document: Document) => {
            promises.push(dispatch(softDeleteDocument(document.id)));
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));

        }).catch(() => {});
    };

    const handleRowDelete = () => {
        setRowMenuAnchor(null);
        if (!rowMenuItem) return;

        confirmRowDeleteModal.current?.open();
    };

    const handleConfirmRowDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        if (rowMenuItem.type === 'category') {
            promises.push(dispatch(softDeleteCategory(rowMenuItem.uuid)));
        } else {
            promises.push(dispatch(softDeleteDocument(rowMenuItem.id)));
        }

        Promise.all(promises).then((responses) => {
            dispatch(fetchDocuments(categoryId));

        }).catch(() => {});
    };

    /**
     * Rename
     */
    const handleRename = () => {
        if (!isSingleSelected) return;
        if (isCategorySelected) {
            handleCategoryRename(selectedCategories[0]);
        }
        if (isDocumentSelected) {
            handleDocumentOpenRename(selectedDocuments[0]);
        }
    };

    const handleRowRename = (item: any) => {
        setRowMenuItem(item);
        setRowMenuAnchor(null);
        if (!item) {
            return;
        }
        if ((item.type === 'category')) {
            handleCategoryRename(item);
        } else {
            handleDocumentOpenRename(item);
        }
    };

    const handleCategoryRename = (item: Category) => {
        dispatch(setCategory(item));
        categoryRenameModal.current?.open();
    };

    const handleDocumentOpenRename = (item: Document) => {
        dispatch(setDocument(item));
        documentRenameModal.current?.open();
    };

    /**
     * Refresh
     */
    const handleRefresh = () => {
        setRowMenuItem(null);
        dispatch(fetchDocuments(categoryId));
        dispatch(fetchTeams());
    };

    /**
     * Team
     */
    const handleGuestTeamCreate = () => {
        if (!isSingleSelected) return;
        let team: any = null;
        if (isCategorySelected) {
            const category: Category = selectedCategories[0];
            setCurrentEntity(category);
            team = teams[category?.uuid]
        }
        if (isDocumentSelected) {
            const document = selectedDocuments[0];
            setCurrentEntity(document);
            team = teams[document?.id]
        }
        if (team) {
            guestTeamMembersUpdateModal?.current?.open()
        } else {
            guestTeamCreateModal?.current?.open()
        }
    };

    const handleTeamDelete = () => {
        setTeamMenuAnchor(null);
        confirmTeamDeleteModal.current?.open();
    };

    const handleConfirmTeamDelete = () => {
        if (!currentTeam) return;

        dispatch(softDeleteTeam(currentTeam.id))
            .unwrap()
            .then(() => {
                dispatch(fetchTeams());
                dispatch(resetCurrentTeam());
                navigate('/documents');
            }).catch(() => { });
    };

    const handleTeamRename = () => {
        setTeamMenuAnchor(null);
        teamRenameModal.current?.open();
    };

    const handleTeamMembersManage = () => {
        setTeamMenuAnchor(null);

        if (currentTeam) {
            teamMembersUpdateModal.current?.open();
        } else {
            defaultTeamMembersUpdateModal.current?.open();
        }
    };

    const handleSyncIntegration = (integration: any) => {
        setTeamMenuAnchor(null);
        dispatch(syncIntegration(integration.id));
    };

    /**
     * Filters
     */
    const applyFilters = () => {
        dispatch(fetchDocuments(categoryId));
    };

    const handleChangeSearch = useMemo(() => {
        return debounce((newValue: string) => {
            dispatch(setParams({ query: newValue }));
            dispatch(fetchDocuments(categoryId));
        }, 400);
    }, []);

    const renderFilter = (key: string, object: any) => {
        if (object.value === null || object.value === undefined) return;

        if (object.value instanceof Date || typeof object.value == 'string') {
            let date = object.value;
            if (typeof object.value === 'string') date = new Date(object.value);
            return <Chip
                label={
                    <>
                        <Typography fontWeight="bold" variant="caption">
                            {object.label}:
                        </Typography>{' '}
                        {date.toISOString()?.slice(0, 10)}
                    </>
                }
                key={key}
                sx={{ mr: 2, mb: 1 }}
                icon={
                    <Avatar onClick={() => handleDeleteFilter(key)} sx={{ width: 10, height: 10 }}>
                        <Close />
                    </Avatar>
                }
            />
        } else {
            return object.value.map(function (el: any) {
                if (el.name || el.label) {
                    return <Chip
                        label={
                            <>
                                <Typography fontWeight="bold" variant="caption">
                                    {object.label}:
                                </Typography>{' '}
                                {(el.name || el.label)}
                            </>
                        }
                        key={key + (el.name || el.label)}
                        sx={{ mr: 2, mb: 1 }}
                        icon={
                            <Avatar onClick={() => handleDeleteFilter(key, el.id)} sx={{ width: 10, height: 10 }}>
                                <Close />
                            </Avatar>
                        }
                    />
                }
            });
        }
    };

    const handleDeleteFilter = (key: string, id: any = null) => {
        dispatch(deleteFilter({ key, id }));
        dispatch(fetchDocuments(categoryId));
    };

    if (!currentTenant) {
        return <></>;
    }

    var tempColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => {
                if (params.row.type === 'category') {
                    return <><Folder /><Typography sx={{ pl: 2 }}>{params.row.name}</Typography></>
                } else {
                    return <><Description /><Typography sx={{ pl: 2 }}>{params.row.name}</Typography>
                        {params.row.version > 1 && <Chip label={`v${params.row.version}`} size="small" sx={{ ml: 1 }} />}
                    </>
                }
            }
        },
        {
            field: 'team',
            headerName: 'Have Access',
            sortable: false,
            resizable: false,
            width: 180,
            renderCell: (params) => {
                let _team = null;
                if (params.row.type === 'category') {
                    _team = teams[params.row.uuid];
                } else {
                    _team = teams[params.row.id];
                }
                if (!_team) return <></>;
                if (!_team.members.length) return <></>;

                return <AvatarGroup max={3}>
                    {_team.members.map((_member: any) => {
                        return <Tooltip
                            key={`m${_member.id}`}
                            title={_member.full_name}
                            placement="top"
                        >
                            <Avatar
                                alt={_member.full_name}
                                sx={{ width: 32, height: 32, fontSize: 16 }}
                            >
                                {_member.initials}
                            </Avatar>
                        </Tooltip>;
                    })}
                </AvatarGroup>;
            }
        },
        {
            field: 'size_formatted',
            headerName: 'Size',
            width: 100
        },
        {
            field: 'updated_at',
            headerName: 'Modified',
            renderCell: (params) => <Moment date={params.row.updated_at} fromNow />
        },
        {
            field: 'updated_by',
            headerName: 'Modified By',
            sortable: false,
            resizable: false,
            align: 'center',
            renderCell: (params) => {
                let _user = users[params.row.updated_by];
                if (!_user) return <></>;

                return <Tooltip
                    key={`u${_user.id}`}
                    title={_user.full_name}
                    placement="top"
                >
                    <Avatar
                        alt={_user.full_name}
                        sx={{ width: 32, height: 32, fontSize: 16 }}
                    >
                        {_user.initials}
                    </Avatar>
                </Tooltip>;
            },
        },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            resizable: false,
            renderCell: (params) => {
                return <Stack
                    spacing={2}
                    className="hidden"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button
                        size="small"
                        disableElevation
                        onClick={() => {
                            handleRowRename(params.row);
                        }}
                        startIcon={<DriveFileRenameOutline />}
                    >
                        Rename
                    </Button>
                </Stack>;
            }
        },
        {
            field: 'context',
            headerName: '',
            sortable: false,
            resizable: false,
            width: 52,
            renderCell: (params) => {
                return <IconButton
                    size="small"
                    color="primary"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setRowMenuAnchor(event.currentTarget);
                        setRowMenuItem(params.row);
                        setIsRowMenuItemCategory(params.row.type == 'category');
                    }}
                >
                    <MoreVert />
                </IconButton>;
            }
        }
    ];

    if (params?.filters || params?.query) {
        tempColumns.splice(2, 0, {
            field: 'folder',
            headerName: 'Folder',
            flex: 1,
            renderCell: (params) => {
                if (params.row.type === 'document' && params.row.category_id !== categoryId) {
                    return <><Typography>{params.row.category_name}</Typography></>
                }
            }
        });
    }

    const columns: GridColDef[] = tempColumns;

    return <>
        <div {...getRootProps({
            onClick: event => event.stopPropagation()
        })}>
            <Box id="notification-bar"></Box>
            <Grid container sx={{
                position: 'sticky',
                m: 0,
                top: -1,
                bgcolor: 'white',
                zIndex: 'appBar',
            }}>
                {hasProjects && <>
                    <Grid item xs={4} sx={{ pt: 2, pb: 1 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <ButtonGroup variant="text">
                                <Button
                                    onClick={(event: React.MouseEvent<HTMLElement>)=> {
                                        setProjectsMenuAnchor(event.currentTarget);
                                    }}
                                >
                                    {currentProject?.name ?? 'Select Project'}
                                </Button>
                                {currentProject &&
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setProjectsMenuAnchor(null);
                                            dispatch(resetCurrentProject());
                                            dispatch(resetCurrentTeam());
                                            navigate('/documents');
                                        }}
                                    >
                                        <Close />
                                    </Button>
                                }
                            </ButtonGroup>
                            <CustomDropdown
                                open={Boolean(projectsMenuAnchor)}
                                anchorEl={projectsMenuAnchor}
                                loading={projectsLoading}
                                createLabel={'Create Project'}
                                onCreate={() => {
                                    setProjectsMenuAnchor(null);
                                    projectTeamCreateModal.current?.open();
                                }}
                                onSelect={(item: any) => {
                                    dispatch(setCurrentProject(item));
                                    setProjectsMenuAnchor(null);
                                    handleSetCurrentTeamByProjectId(item.id);
                                }}
                                onClose={() => {
                                    setProjectsMenuAnchor(null);
                                }}
                                onDebounce={(value: string, callback: (value: any) => void) => {
                                    dispatch(fetchProjects(value))
                                        .unwrap()
                                        .then((response: any) => {
                                            callback(response.data.map((item: any) => {
                                                return {
                                                    title: item.number ? `${item.name} (${item.number})` : item.name,
                                                    ...item
                                                };
                                            }));
                                        }).catch(() => {});
                                }}
                            ></CustomDropdown>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sx={{ pt: 2, pb: 1 }}></Grid>
                    <Grid item xs={4} sx={{ pt: 2, pb: 1 }}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeSearch(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => filterModal?.current?.open()}>
                                            <FilterList />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </>}
                <Grid item xs={8} sx={{ pt: 2, pb: 3 }}>
                    <CustomBreadCrumbs
                        title={currentTeam ? currentTeam.name : 'Documents'}
                        loading={loading}
                        breadcrumbs={breadcrumbs}
                        onClick={(breadcrumb: any) => {
                            navigateTo(currentTeam?.id, breadcrumb.uuid);
                            dispatch(fetchDocuments(breadcrumb.uuid));
                        }}
                        onRootClick={() => {
                            navigateTo(currentTeam?.id);
                            setCategorySelected(null);
                            dispatch(fetchDocuments(null));
                        }}
                        onMenuClick={(event: React.MouseEvent<HTMLElement>, breadcrumb: any) => {
                            setRowMenuAnchor(event.currentTarget);
                            setRowMenuItem({
                                type: 'category',
                                ...breadcrumb
                            });
                            setIsRowMenuItemCategory(true);
                        }}
                        onAccessClick={(event: React.MouseEvent<HTMLElement>) => {
                            setTeamMenuAnchor(event.currentTarget);
                        }}
                    />
                </Grid>
                <Grid item xs={4} sx={{ pt: 2, pb: 3 }}>
                    {!hasProjects &&
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeSearch(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => filterModal?.current?.open()}>
                                            <FilterList />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Fade in={isRowsSelected} hidden={!isRowsSelected} appear={false}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Chip
                                label={`${countRowsSelected} items selected`}
                                color="primary"
                                onClick={handleUnselectAll}
                                onDelete={handleUnselectAll}
                            />
                            <Button
                                disableElevation
                                onClick={handleBulkDelete}
                                startIcon={<DeleteSweep />}
                            >
                                Delete
                            </Button>
                            {isSingleSelected && <Button
                                disableElevation
                                onClick={handleRename}
                                startIcon={<DriveFileRenameOutline />}
                            >
                                Rename
                            </Button>}
                            {isDocumentSelected && !isCategorySelected && <Button
                                disableElevation
                                onClick={handleBulkCopy}
                                startIcon={<ContentCopy />}
                            >
                                Copy
                            </Button>}
                            <Button
                                disableElevation
                                onClick={handleBulkMove}
                                startIcon={<DriveFileMove />}
                            >
                                Move
                            </Button>
                            {false && <Button
                                disableElevation
                                onClick={handleShare}
                                startIcon={<Share />}
                            >
                                Share
                            </Button>}
                            {isSingleSelected && <Button
                                disableElevation
                                onClick={handleGuestTeamCreate}
                                startIcon={<Share />}
                            >
                                Manage Sharing
                            </Button>}

                            {isSingleSelected && isDocumentSelected && <Button
                                disableElevation
                                onClick={handleVersions}
                                startIcon={<RestorePage />}
                            >
                                Versions
                            </Button>}
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => console.log('More')}
                            >
                                <MoreVert />
                            </IconButton>
                        </Stack>
                    </Fade>
                    <Fade in={!isRowsSelected} hidden={isRowsSelected} appear={false}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                onClick={handleUploadMenuClick}
                                startIcon={<Upload />}
                                endIcon={<ExpandMore />}
                            >
                                Upload
                            </Button>
                            <Button
                                color="primary"
                                disableElevation
                                onClick={() => categoryCreateModal?.current?.open()}
                                startIcon={<Add />}
                            >
                                Create Folder
                            </Button>
                            <Button
                                color="primary"
                                disableElevation
                                onClick={handleRefresh}
                                startIcon={<Refresh />}
                            >
                                Refresh
                            </Button>
                        </Stack>
                    </Fade>
                    <Fade in={!!params.filters && !!Object.keys(params.filters).length} hidden={!params.filters || !Object.keys(params.filters).length} appear={false}>
                        <Stack direction="row" alignItems="center" sx={{ pt: 1 }} className="d-flex flex-wrap">
                            {Object.keys(params.filters || {}).map((key: string) => (
                                renderFilter(key, params.filters?.[key])
                            ))}
                        </Stack>
                    </Fade>
                </Grid>
            </Grid>
            <Grid
                container sx={{ m: 0 }}
                onContextMenu={(event) => {
                    event.preventDefault();
                }}
            >
                <DefaultDataGrid
                    className={isDragActive ? 'drag-active' : ''}
                    rows={list}
                    columns={columns}
                    loading={loading}
                    checkboxSelection
                    disableColumnMenu
                    disableRowSelectionOnClick
                    hideFooter
                    rowSelectionModel={rowSelectionModel}
                    getRowId={(row: GridRowModel) => {
                        if (row.type === 'category') {
                            return row.uuid;
                        } else {
                            return row.id;
                        }
                    }}
                    onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => { }}
                    onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {
                        if (params.field != 'name') return;
                        if (params.row.type === 'category') {
                            handleCategory(params.row);
                        } else {
                            handleDocumentOpen(params.row);
                        }
                    }}
                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
                        handleSelectRows(rowSelectionModel);
                    }}
                />
            </Grid>
        </div>
            <Menu
                elevation={1}
                anchorEl={rowMenuAnchor}
                open={Boolean(rowMenuAnchor)}
                onClose={() => {
                    setRowMenuItem(null);
                    setRowMenuAnchor(null);
                }}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
                MenuListProps={{
                    dense: false,
                    subheader: <ListSubheader>
                        <Typography variant='subtitle2' my={2} noWrap>{rowMenuItem?.name}</Typography>
                    </ListSubheader>,
                }}
            >
                {!isRowMenuItemCategory &&
                    <MenuItem onClick={handleRowOpen}>
                        <ListItemIcon>
                            <OpenWith fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Open</ListItemText>
                    </MenuItem>
                }
                {false && <MenuItem onClick={handleRowShare}>
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Share</ListItemText>
                </MenuItem>}
                <MenuItem onClick={handleRowGuestTeam}>
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Manage Sharing</ListItemText>
                </MenuItem>
                <Divider />
                {!isRowMenuItemCategory &&
                    <MenuItem onClick={handleRowVersions}>
                        <ListItemIcon>
                            <RestorePage fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Versions</ListItemText>
                    </MenuItem>
                }
                {!isRowMenuItemCategory && <Divider />}
                <MenuItem onClick={() => handleRowRename(rowMenuItem)}>
                    <ListItemIcon>
                        <DriveFileRenameOutline fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                {!isRowMenuItemCategory &&
                    <MenuItem onClick={handleRowCopy}>
                        <ListItemIcon>
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleRowMove}>
                    <ListItemIcon>
                        <DriveFileMove fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Move</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleRowDelete}>
                    <ListItemIcon>
                        <DeleteSweep fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
            <Menu
                elevation={1}
                anchorEl={teamMenuAnchor}
                open={Boolean(teamMenuAnchor)}
                onClose={() => {
                    setTeamMenuAnchor(null);
                }}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
                MenuListProps={{
                    dense: false,
                    subheader: <ListSubheader>
                        <Typography variant='subtitle2' my={2} noWrap>{currentTeam ? currentTeam?.name : 'Documents'}</Typography>
                    </ListSubheader>,
                }}
            >
                {currentTeam &&
                    <MenuItem onClick={handleTeamRename}>
                        <ListItemIcon>
                            <DriveFileMove fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Rename</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleTeamMembersManage}>
                    <ListItemIcon>
                        <People fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Manage members</ListItemText>
                </MenuItem>
                {integrations && <Divider />}
                {integrations && integrations.map((integration: any, index: number) => {
                    return integration.status === 'active' && <MenuItem key={index} onClick={() => {
                            handleSyncIntegration(integration);
                        }}>
                            <ListItemIcon>
                                <SyncAlt fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={{textTransform: 'capitalize'}}>{integration.name} Sync</ListItemText>
                        </MenuItem>
                    })
                }
                {currentTeam && <Divider />}
                {currentTeam &&
                    <MenuItem onClick={handleTeamDelete}>
                        <ListItemIcon>
                            <DeleteSweep fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                }
            </Menu>

            <DocumentMarkupModal
                ref={documentMarkupModal}
            ></DocumentMarkupModal>
            <DocumentVersionsModal
                ref={documentVersionsModal}
                onClose={handleRefresh}
            ></DocumentVersionsModal>
            <DocumentShareModal
                ref={documentShareModal}
                onClose={handleRefresh}
            ></DocumentShareModal>
            <CategoryShareModal
                ref={categoryShareModal}
                onClose={handleRefresh}
            ></CategoryShareModal>

            <ProjectsContext.Provider value={currentProject}>
                <ProjectTeamCreateModal
                    ref={projectTeamCreateModal}
                    onClose={handleRefresh}
                ></ProjectTeamCreateModal>
            </ProjectsContext.Provider>

            <TeamsContext.Provider value={currentTeam}>
                <TeamCreateModal
                    ref={teamCreateModal}
                    onClose={handleRefresh}
                ></TeamCreateModal>
                <TeamRenameModal
                    ref={teamRenameModal}
                    onClose={handleRefresh}
                ></TeamRenameModal>
                <TeamMembersUpdateModal
                    ref={teamMembersUpdateModal}
                    onClose={handleRefresh}
                ></TeamMembersUpdateModal>
                <DefaultTeamMembersUpdateModal
                    ref={defaultTeamMembersUpdateModal}
                    onClose={handleRefresh}
                ></DefaultTeamMembersUpdateModal>
            </TeamsContext.Provider>

            <EntityContext.Provider value={currentEntity}>
                <GuestTeamCreateModal
                    ref={guestTeamCreateModal}
                    onClose={handleRefresh}
                ></GuestTeamCreateModal>
                <GuestTeamMembersUpdateModal
                    ref={guestTeamMembersUpdateModal}
                    onClose={handleRefresh}
                ></GuestTeamMembersUpdateModal>
            </EntityContext.Provider>

            <CategoryCreateModal
                ref={categoryCreateModal}
                parent={categoryId}
                onSubmit={handleRefresh}
            ></CategoryCreateModal>
            <CategoryRenameModal
                ref={categoryRenameModal}
                onSubmit={handleRefresh}
            ></CategoryRenameModal>
            <DocumentRenameModal
                ref={documentRenameModal}
                onSubmit={handleRefresh}
            ></DocumentRenameModal>

            <CategoriesTreeModal
                ref={moveBulkModal}
                title={`Move ${countRowsSelected} item(s) to...`}
                submitLabel='Move'
                onSubmit={handleBulkMoveSubmit}
            ></CategoriesTreeModal>
            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move ${isRowMenuItemCategory ? 'folder' : 'document'} to...`}
                submitLabel='Move'
                onSubmit={handleRowMoveSubmit}
            ></CategoriesTreeModal>

            <CategoriesTreeModal
                ref={copyBulkModal}
                title={`Copy ${countRowsSelected} item(s) to...`}
                submitLabel='Copy'
                onSubmit={handleBulkCopySubmit}
            ></CategoriesTreeModal>
            <CategoriesTreeModal
                ref={copyRowModal}
                title={`Copy ${isRowMenuItemCategory ? 'folder' : 'document'} to...`}
                submitLabel='Copy'
                onSubmit={handleRowCopySubmit}
            ></CategoriesTreeModal>

            <ConfirmationModal
                ref={confirmBulkDeleteModal}
                text='Are you sure want to delete selected items?'
                onConfirm={handleConfirmBulkDelete}
            ></ConfirmationModal>
            <ConfirmationModal
                ref={confirmRowDeleteModal}
                text='Are you sure want to delete item?'
                onConfirm={handleConfirmRowDelete}
            ></ConfirmationModal>
            <ConfirmationModal
                ref={confirmTeamDeleteModal}
                text='Are you sure want to delete item?'
                onConfirm={handleConfirmTeamDelete}
            ></ConfirmationModal>
            <FilterModal
                ref={filterModal}
                onSubmit={applyFilters}
            ></FilterModal>

            <UploadMenu
                uploadref={uploadMenuRef}
                anchorEl={uploadMenuAnchor}
                open={Boolean(uploadMenuAnchor)}
                onClose={handleUploadMenuClose}
                onComplete={handleUploadMenuComplete}
            ></UploadMenu>
            <ProgressCard
                ref={progressCard}
                title='Uploading files...'
                onOpenClick={handleDocumentOpenProgress}
            ></ProgressCard>
    </>;
};

export default DocumentsPage;
