import {
    configureStore
} from '@reduxjs/toolkit';
import accountReducer from 'slices/accountSlice';
import authReducer from 'slices/authSlice';
import categoriesReducer from 'slices/categoriesSlice';
import categoryReducer from 'slices/categorySlice';
import contactsReducer from 'slices/contactsSlice';
import documentReducer from 'slices/documentSlice';
import documentsReducer from 'slices/documentsSlice';
import navigationItemsReducer from 'slices/navigationItemsSlice';
import progressReducer from 'slices/progressSlice';
import projectReducer from 'slices/projectSlice';
import projectsReducer from 'slices/projectsSlice';
import shareablesReducer from 'slices/shareablesSlice';
import snackbarsReducer, {
    handleFulfilledMiddleware,
    handleRejectedMiddleware
} from 'slices/snackbarsSlice';
import teamReducer from 'slices/teamSlice';
import teamsReducer from 'slices/teamsSlice';
import tenantsReducer from 'slices/tenantsSlice';
import usersReducer from 'slices/usersSlice';
import integrationsReducer from './integrationsSlice';
import invitationsReducer from './invitationsSlice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        account: accountReducer,
        category: categoryReducer,
        categories: categoriesReducer,
        contacts: contactsReducer,
        document: documentReducer,
        documents: documentsReducer,
        invitations: invitationsReducer,
        integrations: integrationsReducer,
        navigationItems: navigationItemsReducer,
        progress: progressReducer,
        shareables: shareablesReducer,
        snackbars: snackbarsReducer,
        tenants: tenantsReducer,
        teams: teamsReducer,
        team: teamReducer,
        projects: projectsReducer,
        project: projectReducer,
        users: usersReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .prepend(handleRejectedMiddleware.middleware)
        .prepend(handleFulfilledMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;