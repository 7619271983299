import {
    Box,
    Button,
    Container,
    Stack,
    Typography
} from '@mui/material';
import VerifyEmailForm from 'components/VerifyEmailForm';
import VerifyPhoneForm from 'components/VerifyPhoneForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAccount } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const VerifyPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const [isShowEmailVerification, setIsShowEmailVerification] = useState(false);
    const [isShowPhoneVerification, setIsShowPhoneVerification] = useState(false);

    useEffect(() => {
        if (user) {
            setIsShowEmailVerification(!user.email_verified_at);
            setIsShowPhoneVerification(!user.phone_verified_at);
        } else {
            setIsShowEmailVerification(false);
            setIsShowPhoneVerification(false);
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    return <>
        <Container component="main" maxWidth="xs">
            <Stack spacing={5} sx={{ mt: 3 }}>
                {isShowEmailVerification && <VerifyEmailForm />}
                {isShowPhoneVerification && <VerifyPhoneForm />}
                {
                    !isShowEmailVerification &&
                    !isShowPhoneVerification &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            minHeight: '90vh',
                        }}
                    >
                        <Stack direction="column" spacing={3} sx={{ textAlign: 'center' }}>
                            <Typography variant="h2">
                                Thank you
                            </Typography>
                            <Typography variant="h5">
                                Your email and phone has been verified!
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => navigate('/')}
                            >Go Back</Button>
                        </Stack>
                    </Box>
                }
            </Stack>
        </Container>
    </>;
};

export default VerifyPage;