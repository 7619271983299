import {
    DeleteOutline,
    FolderCopyOutlined,
    FolderOutlined,
    FolderSharedOutlined
} from '@mui/icons-material';
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import CustomDropdown from 'components/CustomDropdown';
import { Team } from 'interfaces/teams';
import { BaseModalRef } from 'modals/BaseModal';
import TeamCreateModal from 'modals/TeamCreateModal';
import {
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    reset as documentsReset
} from 'slices/documentsSlice';
import { resetCurrentProject } from 'slices/projectsSlice';
import { searchTeams } from 'slices/teamsActions';
import {
    resetCurrentTeam,
    setCurrentTeam
} from 'slices/teamsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DocumentsSideMenu = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const activeLocation = useMemo(() => {
        return location.pathname;
    }, [location]);

    const { loading } = useAppSelector((state) => state.teams);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const [isRootExpanded, setIsRootExpanded] = useState<null | boolean>(false);
    const [teamsMenuAnchor, setTeamsMenuAnchor] = useState<null | HTMLElement>(null);
    const teamCreateModal = useRef<BaseModalRef>(null);


    if (!currentTenant) {
        return <List sx={{pt: 0}}>
            <ListItemButton onClick={() => {
                dispatch(documentsReset());
                dispatch(resetCurrentTeam());
                navigate('/documents/shared');
            }}>
                <ListItemIcon sx={{minWidth: 36}}>
                    <FolderSharedOutlined />
                </ListItemIcon>
                <ListItemText primary="Shared with me" />
            </ListItemButton>
        </List>
    }

    return <>
        <List sx={{pt: 0}}>
            <ListItemButton onClick={() => {
                    if (activeLocation === '/documents') return;

                    dispatch(documentsReset());
                    dispatch(resetCurrentTeam());
                    navigate('/documents');
                }}
                selected={(activeLocation === '/documents') || (activeLocation.includes('/documents/p'))}
            >
                <ListItemIcon sx={{minWidth: 36}}>
                    <FolderOutlined />
                </ListItemIcon>
                <ListItemText primary="Documents" />
            </ListItemButton>

            <ListItemButton
                onClick={(event: React.MouseEvent<HTMLElement>)=> {
                    event.stopPropagation();
                    setTeamsMenuAnchor(event.currentTarget);
                }}
                selected={(activeLocation.includes('/documents/t'))}
            >
                <ListItemIcon sx={{minWidth: 36}}>
                    <FolderCopyOutlined />
                </ListItemIcon>
                <ListItemText primary={'Shared Folders'} sx={{whiteSpace: 'nowrap'}}/>
            </ListItemButton>


            <CustomDropdown
                open={Boolean(teamsMenuAnchor)}
                anchorEl={teamsMenuAnchor}
                loading={loading}
                createLabel={'Create Shared Folder'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onCreate={() => {
                    setTeamsMenuAnchor(null);
                    teamCreateModal.current?.open();
                }}
                onSelect={(item: any) => {
                    dispatch(documentsReset());
                    dispatch(resetCurrentProject());
                    dispatch(setCurrentTeam(item));
                    setTeamsMenuAnchor(null);
                    if (item) {
                        navigate('/documents/t/' + item.id);
                    }
                }}
                onClose={() => {
                    setTeamsMenuAnchor(null);
                }}
                onDebounce={(value: string, callback: (value: any) => void) => {
                    dispatch(searchTeams(value))
                        .unwrap()
                        .then((response: any) => {
                            const items = response.data
                                .filter((team: Team) => {
                                    return team.type === 'custom' && team.entity_id === null;
                                })
                                .map((team: Team) => {
                                    return {
                                        title: team.name,
                                        ...team,
                                    };
                                });

                            callback(items);
                        }).catch(() => {});
                }}
            ></CustomDropdown>
            <ListItemButton onClick={() => {
                    if (activeLocation === '/documents/shared') return;

                    dispatch(documentsReset());
                    dispatch(resetCurrentTeam());
                    navigate('/documents/shared');
                }}
                selected={activeLocation === '/documents/shared'}
            >
                <ListItemIcon sx={{minWidth: 36}}>
                    <FolderSharedOutlined />
                </ListItemIcon>
                <ListItemText primary="Shared with me" sx={{whiteSpace: 'nowrap'}}/>
            </ListItemButton>
            <ListItemButton onClick={() => {
                    if (activeLocation === '/documents/trash') return;

                    dispatch(documentsReset());
                    dispatch(resetCurrentTeam());
                    navigate('/documents/trash');
                }}
                selected={activeLocation === '/documents/trash'}
            >
                <ListItemIcon sx={{minWidth: 36}}>
                    <DeleteOutline />
                </ListItemIcon>
                <ListItemText primary="Deleted files" sx={{whiteSpace: 'nowrap'}}/>
            </ListItemButton>
        </List>
        <TeamCreateModal
            ref={teamCreateModal}
        ></TeamCreateModal>
    </>;
};

export default DocumentsSideMenu;