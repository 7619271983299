import {
    Grid
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DocumentsSideMenu from 'components/DocumentsSideMenu';
import StorageCounter from 'components/StorageCounter';
import { Outlet } from 'react-router-dom';

const SideLayout = () => {
    return <Grid container sx={{
            height: "calc(100vh - 69px)",
        }}>
            <Grid item xs={2} sx={{
                backgroundColor: grey[50],
                overflow: "auto",
            }}>
                <Grid container
                    direction="column"
                    justifyContent="space-between"
                    sx={{
                        height: "100%",
                    }}
                >
                    <DocumentsSideMenu />
                    <StorageCounter />
                </Grid>
            </Grid>
            <Grid item xs={10} sx={{ pl: 3, pr: 3 }}>
                <Outlet />
            </Grid>
        </Grid>;
};

export default SideLayout;