import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCategory, createCategoryNested, fetch, renameCategory, softDeleteCategory } from './categoriesActions';

import { Category } from '../interfaces/documents';

interface CategoryState {
    category: Category | null;
    loading: boolean;
    error: any | null;
};

export const initialState: CategoryState = {
    category: null,
    loading: false,
    error: null
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<Category>) => {
            state.category = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(createCategory.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createCategory.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(createCategory.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(createCategoryNested.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(createCategoryNested.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        builder.addCase(createCategoryNested.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(softDeleteCategory.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(softDeleteCategory.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(softDeleteCategory.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetch.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.category = action.payload.data;
        })
        builder.addCase(fetch.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(renameCategory.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(renameCategory.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.category = action.payload.data;
        })
        builder.addCase(renameCategory.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { setCategory, reset } = categorySlice.actions;
export default categorySlice.reducer;