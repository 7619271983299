import { createSlice } from '@reduxjs/toolkit';
import { NavigationItems } from '../interfaces/navigation';

interface NavigationItemsState {
    mainMenu: NavigationItems;
    settingsMenu: NavigationItems;
    loading: boolean;
    error: any | null;
    success: any | null;
};

export const initialState: NavigationItemsState = {
    mainMenu: [
        {
            url: '/documents',
            title: 'Documents'
        },
        // {
        //     url: '/photos',
        //     title: 'Photos'
        // },
        // {
        //     url: '/drawings',
        //     title: 'Drawings'
        // },
    ],
    settingsMenu: [
        {
            url: '/profile',
            title: 'Profile'
        },
        {
            url: '/applications',
            title: 'Integrations'
        },
    ],
    loading: true,
    error: null,
    success: null
};

const NavigationItemsSlice = createSlice({
    name: 'navigationItems',
    initialState,
    reducers: {
        reset: () => initialState,
    },
});

export const {
    reset,
} = NavigationItemsSlice.actions;
export default NavigationItemsSlice.reducer;