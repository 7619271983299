import {
    Cloud,
    CloudQueue
} from '@mui/icons-material';
import {
    Alert,
    Popover,
    Stack,
    Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
    useEffect,
    useState
} from 'react';
import { fetchStorageCounters } from 'slices/documentsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const StorageDetails = ({
    anchorEl,
    onClose,
} : {
    anchorEl: null | HTMLElement,
    onClose: () => void,
}) => {
    const { storageCounters } = useAppSelector((state) => state.documents);

    return <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
            onClose && onClose();
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <Stack sx={{px: 3, py: 1}}>
            <Typography variant="overline" sx={{lineHeight: 1.8}}>Active {storageCounters?.documents?.size_formatted}</Typography>
            <Typography variant="overline" sx={{lineHeight: 1.8}}>Deleted {storageCounters?.documents_trashed?.size_formatted}</Typography>
            <Typography variant="overline" sx={{lineHeight: 1.8}}>Capacity {storageCounters?.limit?.size_formatted}</Typography>
        </Stack>
    </Popover>
};

const StorageCounter = () => {
    const dispatch = useAppDispatch();
    const { loading, list, storageCounters } = useAppSelector((state) => state.documents);
    const { currentTenant } = useAppSelector((state) => state.tenants);

    const [storageCountersPopoverAnchor, setStorageCountersPopoverAnchor] = useState<null | HTMLElement>(null);

    useEffect(() => {
        dispatch(fetchStorageCounters());
    }, [currentTenant, list]);

    if (storageCounters?.exceeded?.size > 0) {
        return <>
            <StorageDetails
                anchorEl={storageCountersPopoverAnchor}
                onClose={() => {
                    setStorageCountersPopoverAnchor(null);
                }}
            />
            <Alert
                icon={<Cloud />}
                variant="outlined"
                color="warning"
                sx={{m: 1, cursor: "pointer"}}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setStorageCountersPopoverAnchor(event.currentTarget);
                }}
            >
                <Stack>
                    <Typography variant="overline" sx={{lineHeight: 1.5}}>Memory exceeded by</Typography>
                    <Typography variant="overline" sx={{lineHeight: 1.5}}>{storageCounters?.exceeded?.size_formatted}</Typography>
                </Stack>
            </Alert>
        </>;
    }

    if (storageCounters?.total) {
        return <>
            <StorageDetails
                anchorEl={storageCountersPopoverAnchor}
                onClose={() => {
                    setStorageCountersPopoverAnchor(null);
                }}
            />
            <Alert
                icon={<CloudQueue sx={{color: 'rgba(0, 0, 0, 0.54)'}} />}
                variant="outlined"
                color="info"
                sx={{
                    m: 1,
                    cursor: "pointer",
                    backgroundColor: grey[50],
                    color: 'rgba(0, 0, 0, 0.87)',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setStorageCountersPopoverAnchor(event.currentTarget);
                }}
            >
                <Stack>
                    <Typography variant="overline" sx={{lineHeight: 1.5}}>Total memory used</Typography>
                    <Typography variant="overline" sx={{lineHeight: 1.5}}>{storageCounters?.total?.size_formatted}</Typography>
                </Stack>
            </Alert>
        </>;
    }

    return <></>;
};

export default StorageCounter;