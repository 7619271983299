import {
    Close as CloseIcon
} from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    SxProps
} from '@mui/material';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { createPortal } from 'react-dom';


export interface BaseModalRef {
    open: () => void;
    close: () => void;
};

export interface BaseModalProps extends Partial<DialogProps> {
    title?: string;
    titleComponent?: React.ReactElement;
    actions?: React.ReactElement;
    disablePadding?: boolean;
    disableGutters?: boolean;
    showCloseButton?: boolean;
    setOpen?: (value: boolean) => void;
    onClose?: () => void;
};

const BaseModal = (
    props: BaseModalProps,
    ref: React.Ref<unknown>,
) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        title,
        titleComponent,
        actions,
        setOpen,
        onClose,
        disablePadding,
        disableGutters,
        showCloseButton,
        ...purifiedProps
    } = props;
    const defaultProps: BaseModalProps = {
        maxWidth: 'lg',
        fullWidth: true,
        fullScreen: false,
        keepMounted: true,
    };
    const dialogProps = Object.assign(defaultProps, purifiedProps);
    let dialogContentProps: SxProps = {};
    if (disablePadding) {
        dialogContentProps = {p: 0};
    } else if (disableGutters) {
        dialogContentProps = {px: 0};
    }

    useEffect(() => {
        /**
         * Default
         */
        if (props.open != undefined) {
            setIsOpen(props.open);
        }
        if (dialogProps.hideBackdrop != undefined) {
            dialogProps.disableEscapeKeyDown = !dialogProps.hideBackdrop;
        }
    }, [props]);

    const open = () => {
        setIsOpen(true);
        setOpen && setOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        setOpen && setOpen(false);
        onClose && onClose();
    };

    const handleOnClose = (event: React.MouseEvent<HTMLElement>, reason: String) => {
        /**
         * Don't close on reason == "backdropClick|escapeKeyDown" if backdrop is hidden
         */
        if (props.hideBackdrop && reason) return;

        close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const CustomDialogTitle = () => {
        if (titleComponent) {
            return titleComponent;
        }

        if (title) {
            return <DialogTitle>{title}</DialogTitle>
        }

        return <></>;
    };

    return createPortal(
        <Dialog
            {...dialogProps}
            open={isOpen}
            onClose={handleOnClose}
        >
            <CustomDialogTitle />
            {showCloseButton && <IconButton onClick={() => close()}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>}
            <div></div>
            <DialogContent sx={dialogContentProps}>
                {dialogProps.children}
            </DialogContent>
            {
                actions &&
                <DialogActions sx={{py: 2, px: 3}}>
                    {actions}
                </DialogActions>
            }
        </Dialog>,
        document.body
    );
};

export default forwardRef(BaseModal);