import {
    Folder
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import {
    Category
} from 'interfaces/documents';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import {
    fetchCategories
} from 'slices/categoriesActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface CategoriesTreeModalProps {
    title?: string;
    submitLabel?: string;
    cancelLabel?: string;
    onSubmit?: (value: Category|null) => void;
    onCancel?: () => void;
    onClose?: () => void;
};

const CategoriesTreeModal = (
    props: CategoriesTreeModalProps,
    ref: React.Ref<unknown>
) => {
    const dispatch = useAppDispatch();
    const {title, submitLabel, cancelLabel, onCancel, onSubmit, onClose} = props;
    const modal = useRef<BaseModalRef>(null);
    const [rootTitle, setRootTitle] = useState('Documents');
    const [isOpen, setIsOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState<Category|null>(null);
    const {list, excluded, breadcrumbs, parent, loading} = useAppSelector((state) => state.categories);

    const _fetchCategories = useCallback((categoryId: any) => {
        return fetchCategories(categoryId);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setSelectedCategory(null);
            dispatch(_fetchCategories(null));
        }
    }, [isOpen]);

    useEffect(() => {
        /**
         * Nothing to exclude
         */
        if (excluded.length == 0) {
            setIsDisabled(false);
            return;
        }

        /**
         * Root category (null) can't be excluded
         */
        if (!selectedCategory) {
            setIsDisabled(false);
            return;
        }

        /**
         * Find out is selected category excluded
         */
        // const isExcluded = excluded.find((category: Category) => {
        //     return category.uuid == selectedCategory.uuid;
        // });
        // if (isExcluded) {
        //     setIsDisabled(true);
        // } else {
        //     setIsDisabled(false);
        // }
    }, [excluded, selectedCategory]);


    const handleSubmit = () => {
        onSubmit && onSubmit(selectedCategory);
        close();
    };

    const handleCancel = () => {
        onCancel && onCancel();
        close();
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleCategoryClick = (category: Category) => {
        if (excluded.includes(category.id)) return;

        setSelectedCategory(category);
        if (category.has_children) {
            dispatch(_fetchCategories(category.uuid));
        }
    };

    const handleCategoryToggle = (category: Category) => {
        if (excluded.includes(category.id)) return;

        if (selectedCategory?.id == category.id) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(category);
        }
    };

    return <>
        <BaseModal
            ref={modal}
            titleComponent={
                <DialogTitle>
                    {title} <Chip color="primary" icon={<Folder fontSize="small" />} sx={{ml: 1}} label={selectedCategory? selectedCategory.name: rootTitle}/>
                </DialogTitle>
            }
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={onClose}
            disableGutters
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleCancel()}
                    >
                        {cancelLabel ? cancelLabel : 'Cancel'}
                    </Button>
                    <LoadingButton
                        color="primary"
                        disableElevation
                        onClick={() => handleSubmit()}
                        disabled={isDisabled}
                    >
                        {submitLabel ? submitLabel : 'Submit'}
                    </LoadingButton>
                </>
            }
        >
            <Box width={1} px={3}>
                <CustomBreadCrumbs
                    title={rootTitle}
                    variant={'h6'}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => dispatch(_fetchCategories(null))}
                    onClick={(breadcrumb: any) => dispatch(_fetchCategories(breadcrumb.uuid))}
                />
            </Box>
            <Box height={'220px'}>
                <List dense={false}>
                    {loading ? <LinearProgress /> : <Box height={'4px'} width={1}></Box>}
                    {list.map((category: Category, idx: number) => {
                        const isExcluded = excluded.includes(category.id);

                        return <ListItem
                            key={idx}
                            secondaryAction={!isExcluded &&
                                <Checkbox
                                    edge="end"
                                    checked={selectedCategory?.id === category.id}
                                    onChange={() => handleCategoryToggle(category)}
                                />
                            }
                            disablePadding
                        >
                            <ListItemButton
                                selected={selectedCategory?.id === category.id}
                                onClick={() => !isExcluded && handleCategoryClick(category)}
                                disabled={isExcluded}
                            >
                                <ListItemIcon>
                                    <Folder />
                                </ListItemIcon>
                                <ListItemText
                                    primary={category.name}
                                />
                            </ListItemButton>
                        </ListItem>;
                    })}
                </List>
            </Box>
        </BaseModal>
    </>;
};

export default forwardRef(CategoriesTreeModal);