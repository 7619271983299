import {
    PayloadAction,
    createSlice,
    nanoid
} from '@reduxjs/toolkit';
import {
    ProgressItem,
    ProgressItemStatuses,
    ProgressItemTypes,
    ProgressItems
} from 'interfaces/progress';
import { uploadDocument } from './documentsActions';

interface ProgressState {
    isCalm: boolean;
    total: number;
    failed: number;
    succeed: number;
    completed: number;
    items: ProgressItems;
};

export const initialState: ProgressState = {
    isCalm: true,
    total: 0,
    failed: 0,
    succeed: 0,
    completed: 0,
    items: [],
};

const _make = (payload: any) => {
    const item: ProgressItem = {
        id: payload.id || nanoid(),
        type: payload.type || ProgressItemTypes.UPLOAD,
        status: payload.status || ProgressItemStatuses.PENDING,
        data: payload.data,
        loading: false,
        error: null,
        success: null
    };

    return item;
};

const _add = (state: any, payload: any) => {
    const item = _make(payload);
    state.items.push(item);

    state.total = state.items.length;
    state.failed = state.items.filter((item: any) => item.status === ProgressItemStatuses.ERROR).length;
    state.succeed = state.items.filter((item: any) => item.status === ProgressItemStatuses.SUCCESS).length;
    state.completed = state.failed + state.succeed;
};

const _update = (state: any, payload: any) => {
    state.items = state.items.map((item: any) => {
        if (item.id === payload.id) {
            if (payload.status === ProgressItemStatuses.SUCCESS) {
                payload.data.path = item.data.path;
                return Object.assign(item, payload);
            } else {
                item.status = payload.status
                return item;
            }
        } else {
            return item;
        }
    });

    state.total = state.items.length;
    state.failed = state.items.filter((item: any) => item.status === ProgressItemStatuses.ERROR).length;
    state.succeed = state.items.filter((item: any) => item.status === ProgressItemStatuses.SUCCESS).length;

    state.completed = state.failed + state.succeed;
};

const _remove = (state: any, payload: any) => {
    state.items = state.items.filter((item: any) => item.id !== payload);

    state.total = state.items.length;
    state.failed = state.items.filter((item: any) => item.status === ProgressItemStatuses.ERROR).length;
    state.succeed = state.items.filter((item: any) => item.status === ProgressItemStatuses.SUCCESS).length;
    state.completed = state.failed + state.succeed;
};

const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        setItems: (state, action) => {
            action.payload.map((item: any) => {
                _add(state, item);
            });
        },
        addItem: (state, action) => {
            _add(state, action.payload);

            state.isCalm = false;
        },
        removeItem: (state, action) => {
            _remove(state, action.payload);
        },
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(uploadDocument.fulfilled, (state, action: PayloadAction<any>) => {
            if (!action.payload.data) return;
            if (!action.payload.data.file_id) return;

            _update(state, {
                id: action.payload.data.file_id,
                data: action.payload.data,
                status: ProgressItemStatuses.SUCCESS,
            });
        });
        builder.addCase(uploadDocument.rejected, (state, action: any) => {
            let file_id = action?.meta?.arg.get('file_id');

            if (!file_id) return;

            _update(state, {
                id: file_id,
                data: null,
                status: ProgressItemStatuses.ERROR,
            });
        });
    },
});

export const { reset, addItem, removeItem, setItems } = progressSlice.actions;
export default progressSlice.reducer;