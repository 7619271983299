export enum ProgressItemTypes {
    UPLOAD = 'upload',
};

export enum ProgressItemStatuses {
    PENDING = 'pending',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    ERROR = 'error',
};

export interface ProgressItem {
    id: string;
    type: ProgressItemTypes;
    data: any;
    loading: boolean;
    status: ProgressItemStatuses;
    error: any | null;
    success: any | null;
};

export interface ProgressItems extends Array<ProgressItem> {};