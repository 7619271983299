import {
    ExpandMore
} from '@mui/icons-material';
import {
    Breadcrumbs,
    IconButton,
    Link,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import {
    useEffect,
    useState
} from 'react';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

const CustomBreadCrumbs = ({
    breadcrumbs = [],
    title,
    variant = 'h5',
    loading = false,
    onClick,
    onMenuClick,
    onRootClick,
    onAccessClick,
}: {
    breadcrumbs: any[];
    title?: String;
    variant?: Variant;
    loading?: boolean;
    onClick?: (value: any) => void,
    onMenuClick?: (event: React.MouseEvent<HTMLElement>, value: any) => void,
    onRootClick?: () => void,
    onAccessClick?: (event: React.MouseEvent<HTMLElement>) => void,
}) =>  {
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
        setIsEmpty(breadcrumbs.length === 0);
    }, [breadcrumbs]);

    const handleRootClick = () => {
        onRootClick && onRootClick();
    };

    const handleClick = (breadcrumb: any) => {
        onClick && onClick(breadcrumb);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, breadcrumb: any) => {
        onMenuClick && onMenuClick(event, breadcrumb);
    };

    const handleAccessClick = (event: React.MouseEvent<HTMLElement>) => {
        onAccessClick && onAccessClick(event);
    };

    if (isEmpty) {
        return <Breadcrumbs sx={{minHeight: '36px'}}>
            <Stack direction="row">
                <Typography variant={variant}>
                    {title}
                </Typography>
                {
                    onAccessClick &&
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleAccessClick(event);
                        }}
                    >
                        <ExpandMore />
                    </IconButton>
                }
            </Stack>
            {loading && <Typography variant={variant}><Skeleton width={120}/></Typography>}
        </Breadcrumbs>;
    }

    if (loading) {
        return <Breadcrumbs sx={{minHeight: '36px'}}>
            <Typography variant={variant}>{title}</Typography>
            {breadcrumbs.map((breadcrumb: any, idx: any) => {
                return <Typography key={idx} variant={variant}><Skeleton width={120}/></Typography>;
            })}
        </Breadcrumbs>;
    }

    return <Breadcrumbs sx={{minHeight: '36px'}}>
        <Stack direction="row">
            <Link
                href="#"
                onClick={() => handleRootClick()}
                variant={variant}
                underline="hover"
            >
                {title}
            </Link>
        </Stack>
        {breadcrumbs.map((breadcrumb: any, idx: any) => {
            if (idx === breadcrumbs.length - 1) {
                return <Stack key={idx} direction="row">
                    <Typography

                        variant={variant}
                    >
                        {breadcrumb.name}

                    </Typography>
                    {
                        onMenuClick &&
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                handleMenuClick(event, breadcrumb);
                            }}
                        >
                            <ExpandMore />
                        </IconButton>
                    }
                </Stack>;
            } else {
                return <Link
                    key={idx}
                    href="#"
                    onClick={() => handleClick(breadcrumb)}
                    variant={variant}
                    underline="hover"
                >
                    {breadcrumb.name}
                </Link>;
            }
        })}
    </Breadcrumbs>;
};

export default CustomBreadCrumbs;