import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    Stack,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { TeamsContext } from 'interfaces/teams';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { fetchTeams, renameTeam } from 'slices/teamsActions';
import { reset as resetTeam } from 'slices/teamSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface TeamRenameModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const TeamRenameModal = (
    props: TeamRenameModalProps,
    ref: React.Ref<unknown>
) => {
    const team = useContext(TeamsContext);
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.team);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const formId = 'team_rename_form_' + nanoid();

    useEffect(() => {
        if (isOpen && team) {
            reset({
                name: team.name,
            });
        }
    }, [isOpen]);

    const onSubmit = (data: any) => {
        if (!team) return;

        dispatch(renameTeam({
            id: team.id,
            name: data.name,
        }))
            .unwrap()
            .then(() => {
                dispatch(fetchTeams());
                props.onSubmit && props.onSubmit();
                close();
            }).catch(() => {});
    };

    /**
     * Modal
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        dispatch(resetTeam());
        props.onClose && props.onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            title={'Rename shared folder'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        Save
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <Stack spacing={2}>
                    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="name"
                            variant="outlined"
                            label="Shared Folder Name"
                            {...register("name")}
                            error={!!error?.errors?.name}
                            helperText={error?.errors?.name?.[0]}
                            autoFocus
                            fullWidth
                            required
                        />
                    </form>
                </Stack>
                : <Skeleton variant="rounded" width={'100%'} height={56} />
            }
        </BaseModal>
    </>;
};

export default forwardRef(TeamRenameModal);