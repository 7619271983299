import {
    Menu as MenuIcon
} from '@mui/icons-material';
import {
    Alert,
    AppBar,
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import TenantsList from 'components/TenantsList';
import { NavigationItem } from 'interfaces/navigation';
import { Tenant } from 'interfaces/tenants';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchAccount } from 'slices/accountActions';
import { logout } from 'slices/authActions';
import { fetchTenants } from 'slices/tenantsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';


const DefaultAppBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading: loadingAuth } = useAppSelector((state) => state.auth);
    const { loading: loadingAccount, user } = useAppSelector((state) => state.account);
    const { mainMenu, settingsMenu } = useAppSelector((state) => state.navigationItems);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isShowEmailVerificationAlert, setIsShowEmailVerificationAlert] = useState(false);
    const [isShowPhoneVerificationAlert, setIsShowPhoneVerificationAlert] = useState(false);
    const notificationBarEl = document.getElementById('notification-bar');

    useEffect(() => {
        dispatch(fetchAccount());
        dispatch(fetchTenants());
    }, []);

    useEffect(() => {
        const byLocation = !location.pathname.includes('/profile');
        if (byLocation) {
            setIsShowEmailVerificationAlert(byLocation);
            setIsShowPhoneVerificationAlert(byLocation);
        }
        if (user) {
            setIsShowEmailVerificationAlert(!user.email_verified_at);
            setIsShowPhoneVerificationAlert(!user.phone_verified_at);
        } else {
            setIsShowEmailVerificationAlert(false);
            setIsShowPhoneVerificationAlert(false);
        }
    }, [location, user]);

    const handleSingout = async () => {
        dispatch(logout())
            .unwrap()
            .then(() => {
                window.location.reload();
            })
            .catch(() => {});
    };

    const handleResolveVerificationAlert = () => {
        navigate('/profile/verify');
    };

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavigation = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        navigate(item.url);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSettings = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        setAnchorElUser(null);
        navigate(item.url);
    };

    return <>
        <AppBar
            position="static"
            color="primary"
            elevation={0}
        >
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setIsDrawerOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{px: 2}}>
                    <Link to="/">
                        <img src="/images/sitemax-logo-2022.svg" height="40"></img>
                    </Link>
                </Box>
                <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                    {mainMenu.map((item) => (
                        <Button
                            key={item.url}
                            onClick={(e) => handleNavigation(e, item)}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {item.title}
                        </Button>
                    ))}
                </Stack>
                <Box sx={{ flexGrow: 0 }}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar>{user?.initials}</Avatar>
                    </IconButton>
                    <Menu
                        sx={{ mt: 5 }}
                        elevation={1}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box sx={{ width: 240 }}>
                            {settingsMenu.map((item) => (
                                <MenuItem key={item.url} onClick={(e) => handleSettings(e, item)}>
                                    <Typography textAlign="center">{item.title}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem key={'singout'} onClick={handleSingout}>
                                <Typography textAlign="center">{'Sign Out'}</Typography>
                            </MenuItem>
                        </Box>
                    </Menu>
                </Box>
                {
                    (loadingAuth || loadingAccount)
                    && <LinearProgress color="secondary" sx={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%"
                    }}/>
                }
            </Toolbar>
        </AppBar>
        <Drawer
            anchor="left"
            elevation={0}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <Box sx={{ width: 250 }}>
                <Box sx={{ px: 2, pt: 2, pb: 1 }}>
                    <Typography variant="overline">Workspaces</Typography>
                </Box>
                <TenantsList onClick={(tenant: Tenant) => setIsDrawerOpen(false)}/>
            </Box>
        </Drawer>
        {notificationBarEl && createPortal(
            (isShowEmailVerificationAlert || isShowPhoneVerificationAlert) &&
            <Box sx={{pt: 3}}>
                <Alert variant="outlined" severity="warning" action={
                    <Button color="inherit" size="small" onClick={handleResolveVerificationAlert}>Resolve</Button>
                }>
                    {
                        isShowEmailVerificationAlert &&
                        !isShowPhoneVerificationAlert &&
                        <>Your email is not verified</>
                    }
                    {
                        !isShowEmailVerificationAlert &&
                        isShowPhoneVerificationAlert &&
                        <>Your phone number is not verified</>
                    }
                    {
                        isShowEmailVerificationAlert &&
                        isShowPhoneVerificationAlert &&
                        <>Your email and phone number are not verified</>
                    }
                </Alert>
            </Box>,
            notificationBarEl
        )}
    </>;
}

export default DefaultAppBar;