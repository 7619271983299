import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCompanies, fetchContacts } from './contactsActions';

interface ContactsState {
    contacts: any[]
    loading: boolean;
    error: any | null;
};

export const initialState: ContactsState = {
    contacts: [],
    loading: false,
    error: null
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchContacts.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchContacts.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload.data) return;
            state.contacts = action.payload.data;
        })
        builder.addCase(fetchContacts.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
        builder.addCase(fetchCompanies.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (!action.payload.data) return;
            state.contacts = action.payload.data;
        })
        builder.addCase(fetchCompanies.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { reset } = contactsSlice.actions;
export default contactsSlice.reducer;