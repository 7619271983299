import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShareableItems } from 'interfaces/shareables';
import { Tenants } from 'interfaces/tenants';
import { Users } from 'interfaces/users';
import { fetchAll } from './shareablesActions';

interface ShareablesState {
    shareables: ShareableItems;
    tenants: Tenants | null;
    users: Users | null;
    loading: boolean;
    error: any | null;
};

export const initialState: ShareablesState = {
    shareables: [],
    tenants: [],
    users: [],
    loading: false,
    error: null
};

const shareablesSlice = createSlice({
    name: 'shareables',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(fetchAll.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;

            if (!action.payload) return;

            state.shareables = action.payload.data.shareables;
            state.tenants = action.payload.data.tenants;
            state.users = action.payload.data.users;
        })
        builder.addCase(fetchAll.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }
});

export const { reset } = shareablesSlice.actions;
export default shareablesSlice.reducer;
