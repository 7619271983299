export function fileSizeFormat(size: number): String {
    let formatted = '';

    if (size < 1048576) {
        formatted = (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1073741824) {
        formatted = (size / 1048576).toFixed(2) + ' MB';
    } else if (size < 1099511627776) {
        formatted = (size / 1073741824).toFixed(2) + ' GB';
    } else {
        formatted = (size / 1099511627776).toFixed(2) + ' TB';
    }

    return formatted;
};