import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updatePhone, verifyPhone } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const UpdatePhoneForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const { register, reset, handleSubmit } = useForm();
    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const [newPhone, setNewPhone] = useState('');

    useEffect(() => {
        if (user) {
            reset({
                phone: user.phone,
            });
        }
    }, [user]);

    useEffect(() => {
        reset();
    }, [isCodeRequested]);

    const onSubmit = (data: any) => {
        setNewPhone(data.phone);

        dispatch(updatePhone(data))
            .unwrap()
            .then(() => {
                setIsCodeRequested(true);
            }).catch(() => {});
    };

    const onVerify = (data: any) => {
        dispatch(verifyPhone(data))
            .unwrap()
            .then(() => {
                setIsCodeRequested(false);
            }).catch(() => {});
    };

    const handleResend = () => {
        dispatch(verifyPhone({
            phone: newPhone,
        }))
            .unwrap()
            .then(() => {
                setIsCodeRequested(true);
            }).catch(() => {
                setIsCodeRequested(false);
            });
    };

    const handleCancel = () => {
        setIsCodeRequested(false);
    };

    if (isCodeRequested) {
        return <Box>
            <Stack
                direction="column"
                spacing={1}
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h5">Verify phone number using code</Typography>
            </Stack>
            <Box
                component="form"
                onSubmit={handleSubmit(onVerify)}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone_verification_code"
                    label="Verification Code"
                    autoComplete="phone_verification_code"
                    {...register("phone_verification_code", { required: true, minLength: 3, maxLength: 128 })}
                    error={!!error?.errors?.phone_verification_code}
                    helperText={error?.errors?.phone_verification_code?.[0]}
                />
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                >
                    Save
                </LoadingButton>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle2">Didn't receive the code?<br/>
                        <Link href="#" onClick={handleResend}>Resend code</Link>&nbsp;or&nbsp;
                        <Link href="#" onClick={handleCancel}>Change phone number</Link> and try again
                    </Typography>
                </Box>
            </Box>
        </Box>;
    }

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">Update phone number</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                autoComplete="phone"
                autoFocus
                {...register("phone", { required: true, minLength: 9, maxLength: 16 })}
                error={!!error?.errors?.phone}
                helperText={error?.errors?.phone?.[0]}
                InputLabelProps={{ shrink: true }}
            />
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disableElevation
                sx={{ mt: 3, mb: 3 }}
            >
                Save
            </LoadingButton>
        </Box>
    </Box>;
};

export default UpdatePhoneForm;