import {
    Check,
    CheckCircle,
    DeleteSweep,
    Description,
    MoreVert
} from '@mui/icons-material';
import {
    Button,
    Chip,
    DialogTitle,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import {
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowParams,
    GridRowSelectionModel,
    MuiEvent
} from '@mui/x-data-grid';
import DefaultDataGrid from 'components/DefaultDataGrid';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import Moment from 'react-moment';
import {
    reset
} from 'slices/documentSlice';
import {
    deleteDocumentVersion,
    fetchDocumentVersions,
    setDocumentVersion
} from 'slices/documentsActions';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';

export interface DocumentVersionsModalProps {
    onCancel?: (value: Boolean) => void;
    onClose?: () => void;
};

const DocumentVersionsModal = (
    props: DocumentVersionsModalProps,
    ref: React.Ref<unknown>
) => {
    const dispatch = useAppDispatch();
    const {onCancel, onClose} = props;

    const modal                             = useRef<BaseModalRef>(null);
    const confirmRowDeleteModal             = useRef<BaseModalRef>(null);
    const [isOpen, setIsOpen]               = useState(false);
    const [rowMenuItem, setRowMenuItem]     = useState<null | any>(null);
    const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);
    const { loading, document, versions } = useAppSelector((state) => state.document);

    useEffect(() => {
        if (!isOpen) return;
        if (!document) return;

        dispatch(fetchDocumentVersions(document.id));
    }, [isOpen]);

    const handleClose = () => {
        dispatch(reset());
        onClose && onClose();
    };

    const handleCancel = () => {
        onCancel && onCancel(false);
        close();
    };

    const handleRowSetLatest = (item: any) => {
        dispatch(setDocumentVersion({
            id: document?.id,
            version: item?.id
        }))
            .unwrap()
            .then(() => {
                dispatch(fetchDocumentVersions(document?.id));
            })
            .catch();
    };

    const handleRowDelete = () => {
        setRowMenuAnchor(null);

        confirmRowDeleteModal.current?.open();
    };

    const handleConfirmRowDelete = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;
        if (!document) return;
        if (!rowMenuItem) return;

        dispatch(deleteDocumentVersion({
            id: document?.id,
            version: rowMenuItem?.id
        }))
            .unwrap()
            .then(() => {
                dispatch(fetchDocumentVersions(document?.id));
            })
            .catch();
    };

    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const columns: GridColDef[] = [
        {
            field: 'updated_at',
            headerName: 'Modified',
            minWidth: 200,
            renderCell: (params) => <Moment date={params.row.updated_at} format="lll" />
        },
        {
            field: 'metadata',
            headerName: 'User',
            flex: 1,
            minWidth: 240,
            renderCell: (params) => {
                return <Typography>{params.row?.metadata?.user?.name}</Typography>;
            }
        },
        {
            field: 'size_formatted',
            headerName: 'Size',
            minWidth: 80
        },
        {
            field: 'is_latest',
            headerName: 'Is Latest',
            minWidth: 120,
            renderCell: (params) => {
                return params.row.is_latest ? <CheckCircle /> : <></>;
            }
        },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            resizable: false,
            flex: 1,
            minWidth: 240,
            renderCell: (params) => {
                if (params.row.is_latest) return <></>;

                return <Stack
                        spacing={2}
                        className="hidden"
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        width="100%"
                    >
                        <Button
                            size="small"
                            disableElevation
                            onClick={() => {
                                handleRowSetLatest(params.row);
                            }}
                            startIcon={<Check />}
                        >
                            Set As Latest
                        </Button>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setRowMenuAnchor(event.currentTarget);
                                setRowMenuItem(params.row);
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                    </Stack>;
            }
        }
    ];

    return <>
        <BaseModal
            ref={modal}
            titleComponent={
                <DialogTitle>
                    Versions of <Chip color="primary" icon={<Description fontSize="small" />} sx={{ml: 1}} label={document?.name}/>
                </DialogTitle>
            }
            maxWidth={'lg'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => handleCancel()}
                    >
                        Close
                    </Button>
                </>
            }
        >
            <Grid container sx={{m: 0}}>
                <DefaultDataGrid
                    rows={versions}
                    columns={columns}
                    loading={loading}
                    disableColumnMenu
                    hideFooter
                    onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {}}
                    onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {}}
                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {}}
                />
            </Grid>
        </BaseModal>

        <Menu
            elevation={1}
            anchorEl={rowMenuAnchor}
            open={Boolean(rowMenuAnchor)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            onClose={() => {
                setRowMenuItem(null);
                setRowMenuAnchor(null);
            }}
            sx={{ mt: 5 }}
            PaperProps={{
                style: {
                    width: 220,
                },
            }}
            MenuListProps={{
                dense: false,
                subheader: <ListSubheader>
                    <Typography variant='subtitle2' my={2} noWrap>
                        <Moment date={rowMenuItem?.updated_at} format="lll" />
                    </Typography>
                </ListSubheader>,
            }}
        >
            <MenuItem onClick={() => {
                setRowMenuAnchor(null);
                handleRowSetLatest(rowMenuItem);
            }}>
                <ListItemIcon>
                    <Check fontSize="small" />
                </ListItemIcon>
                <ListItemText>Set As Latest</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleRowDelete}>
                <ListItemIcon>
                    <DeleteSweep fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>

        <ConfirmationModal
            ref={confirmRowDeleteModal}
            text='Are you sure want to delete item?'
            onConfirm={handleConfirmRowDelete}
        ></ConfirmationModal>
    </>;
};

export default forwardRef(DocumentVersionsModal);