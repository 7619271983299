import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { resendByEmail, verifyEmail } from 'slices/accountActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const VerifyEmailForm = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.account);
    const { register, reset, handleSubmit } = useForm();

    useEffect(() => {
        if (user) {
            reset({
                email: user.email,
            });
        }
    }, [user]);


    const onVerify = (data: any) => {
        dispatch(verifyEmail(data))
            .unwrap()
            .then(() => {
                //
            }).catch(() => {});
    };

    const handleResend = () => {
        dispatch(resendByEmail())
            .unwrap()
            .then(() => {
                //
            }).catch(() => {});
    };

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5">We send a code on your email address. Please, check your enter code to verify your email address.</Typography>
        </Stack>
        <Box
            component="form"
            onSubmit={handleSubmit(onVerify)}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="email_verification_code"
                label="Verification Code"
                autoComplete="email_verification_code"
                {...register("email_verification_code", { required: true, minLength: 3, maxLength: 128 })}
                error={!!error?.errors?.email_verification_code}
                helperText={error?.errors?.email_verification_code?.[0]}
            />
            <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{ mt: 3, mb: 3 }}
            >
                Save
            </LoadingButton>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle2">Didn't receive the code? <Link href="#" onClick={handleResend}>Resend code</Link></Typography>
            </Box>
        </Box>
    </Box>;
};

export default VerifyEmailForm;