import {
    LinearProgress,
    Stack
} from '@mui/material';
import {
    DataGrid,
    DataGridProps
} from '@mui/x-data-grid';

const DefaultDataGrid = (props: DataGridProps) => {
    const EmptyRowsOverlay = () =>  {
        return <Stack height="100%" alignItems="center" justifyContent="center">No Documents or Folders Found</Stack>;
    };

    return <DataGrid
        autoHeight
        // density="comfortable"
        slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: EmptyRowsOverlay
        }}
        sx={{
            '& .MuiDataGrid-columnHeaders .MuiCheckbox-root': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked': {
                visibility: 'visible',
            },
            '& .MuiDataGrid-columnHeaders:hover .MuiCheckbox-root': {
                visibility: 'visible',
            },
            '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked': {
                visibility: 'visible',
            },
            '& .MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                visibility: 'visible',
            },

            '& .MuiDataGrid-row .hidden': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-row:hover .hidden': {
                visibility: 'visible',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
            },
        }}
        {...props}
    />
};

export default DefaultDataGrid;